import React from 'react';
import axios from 'axios';
import {NavLink, Route} from 'react-router-dom';
import {Redirect} from 'react-router';
import { Modal, Button } from 'react-bootstrap';
import License from "../license/License";
import {Context} from "../configcontext/ConfigContext";
import LoadingSpinner from '../loadingspinner/LoadingSpinner';
import './Billing.css';

class Billing extends React.Component{

  static contextType = Context;

  constructor(props) {
    super(props);
      this.state = {
        licenses: [],
        loading: true,
        modalBody: "",
        modalHeader: "",
        show: false
    }
    this.cancelSuccess = this.cancelSuccess.bind(this);
    this.cancelError = this.cancelError.bind(this);
  }

  cancelSuccess(){
    let value = this.context;
    axios.post('https://www.arremoteassistance.com/api/v1/accounts/readLicenses.php',{},{headers: {Authorization: 'Bearer ' + value.jwt}})
    .then(res => {
        this.setState({loading: false, licenses: res.data});
    }).catch(error => {
        if (error.response) {
          this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.response.data.error, modalHeader: "Error", show: true});
          if (error.response.status === 401) {
            value.logout()
          }
      } else if (error.request) {
        // The request was made but no response was received
          this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.request, modalHeader: "Error", show: true});
      } else {
        // Something happened in setting up the request that triggered an Error
          this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.message, modalHeader: "Error", show: true});
      }
    });
  }

  cancelError(){
    let value = this.context;
    axios.post('https://www.arremoteassistance.com/api/v1/accounts/readLicenses.php',{},{headers: {Authorization: 'Bearer ' + value.jwt}})
    .then(res => {
        this.setState({loading: false, licenses: res.data});
    }).catch(error => {
        if (error.response) {
          this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.response.data.error, modalHeader: "Error", show: true});
          if (error.response.status === 401) {
            value.logout()
          }
      } else if (error.request) {
        // The request was made but no response was received
          this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.request, modalHeader: "Error", show: true});
      } else {
        // Something happened in setting up the request that triggered an Error
          this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.message, modalHeader: "Error", show: true});
      }
    });
  }

  componentDidMount() {
      let value = this.context;
      axios.post('https://www.arremoteassistance.com/api/v1/accounts/readLicenses.php',{},{headers: {Authorization: 'Bearer ' + value.jwt}})
      .then(res => {
          this.setState({loading: false, licenses: res.data});
      }).catch(error => {
          if (error.response) {
            this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.response.data.error, modalHeader: "Error", show: true});
            if (error.response.status === 401) {
              value.logout()
            }
        } else if (error.request) {
          // The request was made but no response was received
            this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.request, modalHeader: "Error", show: true});
        } else {
          // Something happened in setting up the request that triggered an Error
            this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.message, modalHeader: "Error", show: true});
        }
      });
    }

  render(){
    let value = this.context;
    if(value.jwt == ""){
        return(
          <Route>
            <Redirect to="/login" />
          </Route>
        );
    }else{
      if(value.userPermissions.includes("billing:update")){
        return (
          <div className="mainWrapper">
            <div className="mainWindow">
                {this.state.loading &&
                  <LoadingSpinner />
                }
                <Modal show={this.state.show} onHide={this.closeModal}>
                  <Modal.Header closeButton>
                    <Modal.Title>{this.state.modalHeader}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>{this.state.modalBody}</Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={this.closeModal}>
                      Close
                    </Button>
                  </Modal.Footer>
              </Modal>
                <div className="pageHeader">
                  BILLING
                  <NavLink to="/license/create" >
                    <Button variant="primary" className="addUserBtn">+ Add License</Button>
                  </NavLink>
                </div>
                <div className="plainBody">
                    <div className="licenseFilter">
                      <div className="licenseFilterExpireDate">
                      Expiration Date
                      </div>
                      <div className="licenseFilterDaysRemaining">
                      Days Remaining
                      </div>
                      <div className="licenseFilterStatus">
                      Status
                      </div>
                      <div className="licenseFilterId">
                      Subscription Id
                      </div>
                      <div className="licenseFilterStatus">
                      Auto Renew
                      </div>
                      <div className="licenseUpdate">
                      
                      </div>
                    </div>
                  <div className="userViewWrapper">
                    {this.state.licenses.map(license => {
                      var expireDateString = license.expireDate.split(' ')[0];
                      return(
                        <License key={license.id} expireDate={expireDateString} licenseId={license.licenseId} licenseStatus={license.licenseStatus} cancelError={this.cancelError} cancelSuccess={this.cancelSuccess} autoRenew={license.autoRenew}/>
                      );
                    })}
                  </div>
                </div>
            </div>
          </div>
        );
    }else{
         return(
            <Route>
              <Redirect to="/dashboard" />
            </Route>
         );
      }
  }
  }
}

export default Billing;