import React from 'react';
import './Call.css';

class Call extends React.Component{

  render(){
    var start_date = new Date(this.props.callDate);
    var minutes = Math.floor(this.props.callDuration/60);
    var seconds = this.props.callDuration - (minutes*60);
    if(this.props.callCreatedBy == ""){
      var callCreatedBy = "&nbsp;";
    }else{
      var callCreatedBy = this.props.callCreatedBy;
    }
    if(this.props.callReceivedBy == ""){
      var callReceivedBy = "&nbsp;";
    }else{
      var callReceivedBy = this.props.callReceivedBy;
    }
    return (
      <div className="callWrapper">
        <div className="callId">
          {this.props.callId}
        </div>
        <div className="callMadeBy">
          {this.props.callCreatedBy == "" ?
            <div>
              &nbsp;
            </div>
            :
            (this.props.callCreatedBy)
          }
        </div>
        <div className="callName">
          {this.props.callReceivedBy == "" ?
            <div>
              &nbsp;
            </div>
            :
            (this.props.callReceivedBy)
          }
        </div>
        <div className="callDuration">
          {minutes} min, {seconds} sec
        </div>
        <div className="callDate">
          {this.props.callDate}
        </div>
      </div>
    );
  }
}

export default Call;