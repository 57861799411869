import React from 'react';
import axios from 'axios';
import {NavLink, Route} from 'react-router-dom';
import {Redirect} from 'react-router';
import { Modal, Button } from 'react-bootstrap';
import {Context} from "../configcontext/ConfigContext";
import './Support.css';

class Support extends React.Component{

  static contextType = Context;

  constructor(props) {
    super(props);
      this.state = {
    }
  }

  render(){
    let value = this.context;
    if(value.jwt == ""){
      return(
        <Route>
          <Redirect to="/login" />
        </Route>
      );
    }else{
      return (
        <div className="mainWrapper">
          <div className="mainWindow">
              <div className="pageHeader">
                SUPPORT
              </div>
              <div className="plainBody">
                Support coming soon!
              </div>
          </div>
        </div>
      );
    }
  }
}

export default Support;