import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
import createHistory from 'history/createBrowserHistory';
import { Route, Router, Switch } from 'react-router-dom';
import { ConfigContext as Provider } from "../configcontext/ConfigContext";
import { Redirect } from 'react-router';
import '../../index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Authorize from '../authorize/Authorize';
import Home from '../home/Home';
import Header from '../header/Header';
import Leftrail from '../leftrail/Leftrail';
import CreateAccount from '../createaccount/CreateAccount';
import Login from '../login/Login';
import Dashboard from '../dashboard/Dashboard';
import ManageUsers from '../manageusers/ManageUsers';
import CreateUser from '../createuser/CreateUser';
import RemoteHelp from '../remotehelp/RemoteHelp';
import Analytics from '../analytics/Analytics';
import Billing from '../billing/Billing';
import ProfileSettings from '../profilesettings/ProfileSettings';
import AccountSettings from '../accountsettings/AccountSettings';
import Knowledgebase from '../knowledgebase/Knowledgebase';
import Support from '../support/Support';
import HowItWorks from '../howitworks/HowItWorks';
import RemoteLaunch from '../remotelaunch/RemoteLaunch';
import About from '../about/About';
import Careers from '../careers/Careers';
import Contact from '../contact/Contact';
import Privacy from '../privacy/Privacy';
import VerifyAccount from '../verifyaccount/VerifyAccount';
import ForgotPassword from '../forgotpassword/ForgotPassword';
import ResetPassword from '../resetpassword/ResetPassword';
import CreateLicense from '../createlicense/CreateLicense';
import Solutions from '../solutions/Solutions';
import ManageContacts from '../managecontacts/ManageContacts';
import CreateContact from '../createcontact/CreateContact';
import Pricing from '../pricing/Pricing';

const history = createHistory()
ReactGA.initialize('UA-135500571-2');
history.listen((location, action) => {
    ReactGA.pageview(location.pathname + location.search);
    //console.log(location.pathname)
});

class App extends React.Component {

  render() {
    return (
      <div className="wrapper">
      <Provider>
        <Router history={history}>
          <div>
            <Switch> 
                <Route exact path="/authorize" component={Authorize} />
                <Route exact path="/" component={Home} />
                <Route exact path="/account/create" component={CreateAccount} />
                <Route exact path="/login" component={Login} />
                <Route exact path="/dashboard" component={Dashboard} />
                <Route exact path="/users/view" component={ManageUsers} />
                <Route exact path="/users/create" component={CreateUser} />
                <Route exact path="/remotehelp" component={RemoteHelp} />
                <Route exact path="/remotelaunch" component={RemoteLaunch} />
                <Route exact path="/analytics" component={Analytics} />
                <Route exact path="/billing" component={Billing} />
                <Route exact path="/profile/settings" component={ProfileSettings} />
                <Route exact path="/account/settings" component={AccountSettings} />
                <Route exact path="/knowledgebase" component={Knowledgebase} />
                <Route exact path="/support" component={Support} />
                <Route exact path="/howitworks" component={HowItWorks} />
                <Route exact path="/about" component={About} />
                <Route exact path="/careers" component={Careers} />
                <Route exact path="/verifyaccount" component={VerifyAccount} />
                <Route exact path="/privacy" component={Privacy} />
                <Route exact path="/contact" component={Contact} />
                <Route exact path="/resetpassword" component={ResetPassword} />
                <Route exact path="/forgotpassword" component={ForgotPassword} />
                <Route exact path="/license/create" component={CreateLicense} />
                <Route exact path="/solutions" component={Solutions} />
                <Route exact path="/contacts" component={ManageContacts} />
                <Route exact path="/contact/create" component={CreateContact} />
                <Route exact path="/pricing" component={Pricing} />
                <Route render={() => <Redirect to="/" />} />
            </Switch>
            <Header /> 
            <Leftrail /> 
          </div>
        </Router>
      </Provider>
    </div>
    )
  }
}

export default App