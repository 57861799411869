import React from 'react';
import UserMenuOption from '../usermenuoption/UserMenuOption';
import {Context} from "../configcontext/ConfigContext";
import ReactDOM from 'react-dom'
import './UserMenu.css';

class UserMenu extends React.Component{

  static contextType = Context;
  
  constructor(props) {
    super(props);
    this.state = {
      showMenu: false
    }
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    this.setState({showMenu: this.props.showMenu});
  }

  handleClick(e){
    let element = document.getElementById("userMenuWrapper");
    if(e.id == "contactId"){

    }else{
      if(this.state.showMenu == false){
        this.setState({showMenu: true});
        this.props.showMenuFunction(true);
        ReactDOM.findDOMNode(element).style.display = "block";
      }else{
        this.setState({showMenu: false});
        this.props.showMenuFunction(false);
        ReactDOM.findDOMNode(element).style.display = "none";
      }
    }
  }

  render(){
    let value = this.context;
      return(
        <div className="userMenuWrapper" id="userMenuWrapper">
          <div className="userMenuContactId">
            <div style={{fontSize: "20px"}}><u>CONTACT ID</u></div>
            {value.contactId}
          </div>
          {value.userPermissions.includes("profile:update") &&
          <div onClick={this.handleClick}>
            <UserMenuOption goto="/profile/settings" text="Profile Settings"/>
          </div>
          }
          <div onClick={this.handleClick}>
            <UserMenuOption goto="/knowledgebase" text="Knowledge Base"/>
          </div>
          <div onClick={this.handleClick}>
            <UserMenuOption goto="/support" text="Support Center" />
          </div>
        </div>
      );
  }
}

export default UserMenu;