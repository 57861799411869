import React from 'react';
import { Route } from 'react-router-dom';
import { Redirect } from 'react-router';
import axios from 'axios';
import MainHeader from '../mainheader/MainHeader';
import MainFooter from '../mainfooter/MainFooter';
import {Context} from "../configcontext/ConfigContext";
import LoadingSpinner from '../loadingspinner/LoadingSpinner';
import { Modal, Button } from 'react-bootstrap';
import './VerifyAccount.css';

class VerifyAccount extends React.Component{

  static contextType = Context;

  constructor(props) {
    super(props);
    this.state = {
      show: false,
      modalBody: "",
      modalHeader: "",
      loading: true,
      verified: false
    }
    this.showModal = this.showModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  showModal(){
    this.setState({show: true});
  }

  closeModal(){
    this.setState({show: false});
  }

  componentDidMount(){
    const value = this.context;
    let search = new URLSearchParams(this.props.location.search);
    if(search.get("t") && search.get("act")){
      var token = search.get("t");
      var email = search.get("act");
      axios.post('https://www.arremoteassistance.com/api/v1/accounts/verifyAccount.php',{token: token, email: email})
        .then(res => {
          this.setState({loading: false, show: true, modalHeader: "Success", modalBody: res.data.message, verified: true})
        }).catch(error => {
          if (error.response) {
              this.setState({loading: false, modalBody: error.response.data.error, modalHeader: "Error", show: true});
          } else if (error.request) {
            // The request was made but no response was received
              this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.request, modalHeader: "Error", show: true});
          } else {
            // Something happened in setting up the request that triggered an Error
              this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.message, modalHeader: "Error", show: true});
          }
        });
    }else{
      this.setState({loading: false, show: true, modalHeader: "Error", modalBody: "Sorry, it looks like some required information was missing. We were unable to verify your account."});
    }
  }

  render(){
    const{show} = this.state;
    return (
      <div className="mainWrapper">
        <Modal show={show} onHide={this.closeModal}>
          <Modal.Header closeButton>
            <Modal.Title>{this.state.modalHeader}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{this.state.modalBody}</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.closeModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        <MainHeader />
        <div className="pageTitle">
        ACCOUNT VERIFICATION
        </div>
        <div className="pageDescription">
          {this.state.verified == false ?
            <div>
              Please wait while we verify your account...
            </div>
            :
            <div>
              Your account has been verified! Please proceed to <a href="/login">login</a> to start taking advantage of the most advanced AR Remote Assistance solution.
            </div>
          }
        </div>
        <div className="footerSpace">
        </div>
        <MainFooter />
      </div>
    );
  }
}

export default VerifyAccount;