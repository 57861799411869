import React from 'react';
import axios from 'axios';
import MainHeader from '../mainheader/MainHeader';
import MainFooter from '../mainfooter/MainFooter';
import {Context} from "../configcontext/ConfigContext";
import './HowItWorks.css';

class HowItWorks extends React.Component{

  static contextType = Context;

  constructor(props) {
    super(props);
      this.state = {
    }
    this.focusUtilities = React.createRef();
    this.focusIndividuals = React.createRef();
    this.focusManufacturers = React.createRef();
    this.focusCustomerService = React.createRef();
    this.handleOnClick = this.handleOnClick.bind(this);
  }

  handleOnClick(event){
    if(event.target.id == "focusUtilities"){
      this.focusUtilities.current.scrollIntoView({ 
        behavior: "smooth", 
        block: "nearest"
      })
    }
    if(event.target.id == "focusIndividuals"){
      this.focusIndividuals.current.scrollIntoView({ 
        behavior: "smooth", 
        block: "nearest"
      })
    }
    if(event.target.id == "focusManufacturers"){
      this.focusManufacturers.current.scrollIntoView({ 
        behavior: "smooth", 
        block: "nearest"
      })
    }
    if(event.target.id == "focusCustomerService"){
      this.focusCustomerService.current.scrollIntoView({ 
        behavior: "smooth", 
        block: "nearest"
      })
    }
  }

  render(){
    let value = this.context;
    return (
      <div>
        <MainHeader />
        <div className="pageTitle">
          HOW IT WORKS
        </div>
        <div className="pageDescription">
          <div className="howitworksExplanation">
            <font size="6"><b>WHAT IS ARREMOTEASSISTANCE?</b></font>
            <p>
            In these unprecedented times, individuals and teams are looking for ways to communicate and work together while maintaining a safe distance. Ultimately, this has lead to an increase in the need for tools to work remotely. 
            <br/>
            <br/>
            ARRemoteAssistance allows you to communicate with family, friends, coworkers and more remotely. Not only are you able to communicate via video and voice, but we've taken video chat to the next level.
            <br/>
            <br/>
            We've included tools that allow you to more clearly communicate using augmented reality. Using augmented reality, we allow you to leave drawings, notes, annotations and more that stick to their locations in the real-world. This allows you to more clearly communicate your thoughts.
            <br/>
            <br/>
            Our enterprise customers have added capabilities that allow you to record your sessions and save them to the cloud. As our workforce continues to age, we need new ways to retain the depth of knowledge that leaves when employees retire. ARRemoteAssistance allows you to walk through instructions, leaving augmented reality viusals to better communicate, and save those instructions to help train new employees and retain that valuable knowledge.
            </p>
            <br/>
            <font size="6"><b>WHAT INDUSTRIES DO WE SERVE?</b></font>
            <p>
            ARRemoteAssistance can help individuals and enterprise customers alike. Whether you're helping your friend change a tire, or your walking an employee through a complicated process, we've got you covered. Below are a few of the common industries we serve, but we are certainly not limited to the industries listed below.
            </p>
            <br/>
          </div>
          <div className="howitworksIndustryWrapper" onClick={this.handleOnClick} id="focusIndividuals">
            <div className="industryWrapper" id="focusIndividuals">
              <div className="industryWrapperTitle" id="focusIndividuals">
                Individuals
              </div>
              <div className="industryWrapperImg" id="focusIndividuals">
                <img src="/images/utilities.png" alt="Utilities" id="focusIndividuals"/>
              </div>
              <div className="industryWrapperNextImg" id="focusIndividuals">
                <img src="/images/nextarrow.png" alt="Go To Detail" id="focusIndividuals"/>
              </div>
            </div>
            <div className="industryWrapper" onClick={this.handleOnClick} id="focusManufacturers">
              <div className="industryWrapperTitle" id="focusManufacturers">
                Manufacturing
              </div>
              <div className="industryWrapperImg" id="focusManufacturers">
                <img src="/images/utilitylocator.png" alt="Utilities" id="focusManufacturers"/>
              </div>
              <div className="industryWrapperNextImg" id="focusManufacturers">
                <img src="/images/nextarrow.png" alt="Go To Detail" id="focusManufacturers"/>
              </div>
            </div>
            <div className="industryWrapper" onClick={this.handleOnClick} id="focusUtilities">
              <div className="industryWrapperTitle" id="focusUtilities">
                Utilities
              </div>
              <div className="industryWrapperImg" id="focusUtilities">
                <img src="/images/excavators.png" alt="Utilities" id="focusUtilities"/>
              </div>
              <div className="industryWrapperNextImg" id="focusUtilities">
                <img src="/images/nextarrow.png" alt="Go To Detail" id="focusUtilities"/>
              </div>
            </div>
            <div className="industryWrapper" onClick={this.handleOnClick} id="focusCustomerService">
              <div className="industryWrapperTitle" id="focusCustomerService">
                Customer Service
              </div>
              <div className="industryWrapperImg" id="focusCustomerService">
                <img src="/images/architects.png" alt="Utilities" id="focusCustomerService"/>
              </div>
              <div className="industryWrapperNextImg" id="focusCustomerService">
                <img src="/images/nextarrow.png" alt="Go To Detail" id="focusCustomerService"/>
              </div>
            </div>
          </div>
          <div className="industryDetailWrapper" ref={this.focusIndividuals}>
            <div className="industryDetailTitle">
              Individuals
            </div>
            <div className="industryDetailDescription">
            Whether you're helping a friend change a tire, or walking a friend through how to perform maintenance on their car, we've got you covered. ARRemoteAssistance allows you to more clearly communicate with others by providing augmented reality visuals on top of your video chat. 
            <br/>
            <br/>
            Annotations allow you to circle objects in the real world. Remote users can add drawings and annotations that blend with the real-world to more clearly communicate.
            </div>
            <div className="industryDetailImage">
              <img src="/images/arutility_screenshot_2.png" alt="handholdingphone" />
            </div>
          </div>
          <div className="industryDetailWrapper" ref={this.focusManufacturers}>
            <div className="industryDetailTitle">
              Manufacturing
            </div>
            <div className="industryDetailDescription">
              Assist staff with questions that arise during the manufacturing process. Whether that is a question regarding how to use a specific piece of machinery or troubleshooting a malfunctioning piece of machinery, the possibilities are endless
            </div>
            <div className="industryDetailImage">
              <img src="/images/factoryWorker.png" alt="handholdingphone" />
            </div>
          </div>
          <div className="industryDetailWrapper" ref={this.focusUtilities}>
            <div className="industryDetailTitle">
              Utilities
            </div>
            <div className="industryDetailDescription">
              Avoid dangerous and costly damages to underground assets by maintaining a constant visual of where things are located while performing excavation. Placing ARUtility in the hands of operators, inspectors and field crews can significantly reduce the likelihood of damage. Digital markings never get removed during construction or inclement weather. Take advantage of the collaboration tool ahead of construction and quickly and easily gain access to all underground and above ground assets prior to digging.
            </div>
            <div className="industryDetailImage">
              <img src="/images/watertech.jpg" alt="handholdingphone" />
            </div>
          </div>
          <div className="industryDetailWrapper" ref={this.focusCustomerService}>
            <div className="industryDetailTitle">
              Customer Service
            </div>
            <div className="industryDetailDescription">
              Whether you're helping customers put products together or assisting customers with a question on how to use one of your products, remote assistance can play a key role in your customer satisfaction. Allow your customer service reps to see exactly what your customers see and more clearly communicate and get answers to your customers.
            </div>
            <div className="industryDetailImage">
              <img src="/images/customerservice.jpg" alt="handholdingphone" />
            </div>
          </div>
        </div>
        <div className="footerSpace">
        </div>
        <MainFooter />
      </div>
    );
  }
}

export default HowItWorks;