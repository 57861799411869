import React from 'react';
import axios from 'axios';
import {Redirect} from 'react-router';
import {Route, Link} from 'react-router-dom';
import LoadingSpinner from '../loadingspinner/LoadingSpinner';
import {Context} from "../configcontext/ConfigContext";
import { Modal, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './CreateContact.css';

class CreateContact extends React.Component{

	static contextType = Context;

	constructor(props) {
		super(props);
			this.state = {
		    loading: true,
		    userEmail: "",
		    userPhone: "",
		    userContactId: "",
		    modalBody: "",
		    fullName: "",
		    modalHeader: "",
		    show: false
		}
		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.submitForm = this.submitForm.bind(this);
		this.showModal = this.showModal.bind(this);
		this.closeModal = this.closeModal.bind(this);
	}

	componentDidMount(){
		this.setState({loading: false});
	}

  	handleChange(e){
  		this.setState({[e.target.name]:e.target.value});
  	}

  	submitForm(){
  		let value = this.context;
  		this.setState({loading: true});
  		axios.post('https://www.arremoteassistance.com/api/v1/contacts/create.php',{contactName: this.state.fullName, contactEmail: this.state.userEmail, contactPhone: this.state.userPhone, contactId: this.state.userContactId},{headers: {Authorization: 'Bearer ' + value.jwt}})
      		.then(res => {
       			this.setState({modalBody: "Contact Successfully Created", modalHeader: "Success", show: true, loading: false});
    		}).catch(error => {
      			if (error.response) {
	        	this.setState({loading: false, modalBody: error.response.data.error, modalHeader: "Error", show: true});
	        	if (error.response.status === 401) {
		            value.logout()
		         }
		    } else if (error.request) {
		      // The request was made but no response was received
		        this.setState({loading: false, modalBody: error.request, modalHeader: "Error", show: true});
		    } else {
		      // Something happened in setting up the request that triggered an Error
		        this.setState({loading: false, modalBody: error, modalHeader: "Error", show: true});
		    }
  		});
  	}

  	handleSubmit(){
  		let value = this.context;
  		if(this.state.userEmail != "" && this.state.userPhone != "" && this.state.fullName != "" && this.state.userContactId != ""){
			this.submitForm();
		}else{
			this.setState({ modalBody: "Sorry, it looks like some required information is missing. Please try again.", modalHeader: "Error", show: true});
		}
  	}

  	showModal(){
    	this.setState({show: true});
  	}

  	closeModal(){
    	this.setState({show: false});
  	}

  	render(){
	  	let value = this.context;
	  	const {loading, show} = this.state;
	  	if(value.jwt == ""){
	      return(
	        <Route>
	          <Redirect to="/login" />
	        </Route>
	      );
	    }else{
		    if(value.userPermissions.includes("user:create")){
			    return (
			   		<div className="mainWrapper">
			    		<div className="mainWindow">
				   			{this.state.loading && 
				   				<LoadingSpinner />
				   			}
				   			<Modal show={show} onHide={this.closeModal}>
					            <Modal.Header closeButton>
					              <Modal.Title>{this.state.modalHeader}</Modal.Title>
					            </Modal.Header>
					            <Modal.Body>{this.state.modalBody}</Modal.Body>
					            <Modal.Footer>
					              <Button variant="secondary" onClick={this.closeModal}>
					                Close
					              </Button>
					            </Modal.Footer>
					        </Modal>
					      	<div className="pageHeader">
					      	CREATE NEW CONTACT
					      		<div className="userCreateClose">
					      			<Link to="/contacts">
					      				CLOSE
					      			</Link>
				      			</div>
					      	</div>
					      	<div className="whiteWrapperBody">
						      	<div id="createUserForm">
						      		<font color="#c62828">Full Name:</font>
						        	<br/>
						        	<input type="text" name="fullName" id="fullName" className="companyInput" placeholder="Full Name..." value={this.state.firstName} onChange={this.handleChange} />	
					        		<br/>
						        	<br/>
									<font color="#c62828">Email:</font>
						            <br/>
						        	<input type="email" name="userEmail" id="userEmail" className="companyInput" placeholder="Email..." value={this.state.userEmail} onChange={this.handleChange} />			        
						        	<br/>
						        	<br/>
						        	<font color="#c62828">Phone Number:</font>
						        	<br/>
						        	<input type="tel" pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" name="userPhone" id="userPhone" className="companyInput" placeholder="Phone # (Format: xxx-xxx-xxxx)" value={this.state.userPhone} onChange={this.handleChange} />	
					        		<br/>
						        	<br/>
						        	<font color="#c62828">Contact Id:</font>
						            <br/>
						        	<input type="text" name="userContactId" id="userContactId" className="companyInput" placeholder="Contact Id..." value={this.state.userContactId} onChange={this.handleChange} />	
						        </div>
					        	<button id="submitUserCreate" className="submitRedBtn" onClick={this.handleSubmit} >CREATE CONTACT</button>
					      	</div>
					    </div>
			      	</div>
			    );
			}else{
		       return(
		         	<Route>
		            	<Redirect to="/dashboard" />
		          	</Route>
		       );
		    }
	  	}
  	}
}

export default CreateContact;