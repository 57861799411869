import React from 'react';
import {Link} from 'react-router-dom';
import {Context} from "../configcontext/ConfigContext";
import './MainFooter.css';

class MainFooter extends React.Component{

  static contextType = Context;
  
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  render(){
    let value = this.context;
    return(
      <div className="mainFooterWrapper">
        <div className="footerSocialWrapper">
          <div className="footerSocialImage">
            <img src="/images/iconlogofull.png" alt="ARUtility Logo" />
          </div>
          <div className="footerSocialLogos">
            <a href="https://www.facebook.com/ARUtility-1986933624955089" target="_blank"><img src="/images/facebook.png" alt="Facebook Logo"/></a><a href="https://twitter.com/ArUtility" target="_blank"><img src="/images/twitter.png" alt="Twitter Logo"/></a><a href="https://www.instagram.com/arutility" target="_blank"><img src="/images/instagram.png" alt="Instagram Logo"/></a><a href="https://www.linkedin.com/company/arutility" target="_blank"><img src="/images/linkedinlogo.png" alt="LinkedIn Logo"/></a><a href="https://www.youtube.com/channel/UCKrdbiN0xkKR1jRP1QSQsmg" target="_blank"><img src="/images/youtube.png" alt="Youtube Logo"/></a>
          </div>
          <div className="footerDownloads">
            <a href="https://play.google.com/store/apps/details?id=com.ARUtility.RemoteAssistanceStandalone" target="_blank"><img src="/images/googleplaybtn.png" alt="Download on Google Play"/></a><a href="https://apps.apple.com/us/app/id1529740112" target="_blank"><img src="/images/applestore.png" alt="Download on Apple App Store"/></a>
          </div>
        </div>
        <div className="footerMenu">
          <Link to="/">Home  </Link>|<Link to="/howitworks" className="footer">  How It Works  </Link>|<Link to="/about" className="footer">  About  </Link>|<Link to="/pricing" className="footer">  Pricing  </Link>|<Link to="/news" className="footer">  News  </Link>|<Link to="/contact" className="footer">  Contact  </Link>|<Link to="/login" className="footer">  Login  </Link>
          <br/>
          <br/>
          4942 Dawn Ave, Ste 132
          <br/>
          East Lansing, MI 48823
          <br/>
          (504)434-6796
          <br/>
          <a href="/privacy"><font size="1">Privacy Policy</font></a>
        </div>
        <div className="footerEmpty">
          
        </div>
      </div>
    );
  }
}

export default MainFooter;