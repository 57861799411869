import React from 'react';
import axios from 'axios';
import {Context} from "../configcontext/ConfigContext";
import LoadingSpinner from '../loadingspinner/LoadingSpinner';
import {Button,Tooltip, OverlayTrigger} from 'react-bootstrap';
import './UserContact.css';

class UserContact extends React.Component{

  static contextType = Context;

  constructor(props) {
    super(props);
      this.state = {
        loading: false,
        userName: "",
        startName: "",
        userEmail: "",
        startUserEmail: "",
        userPhone: "",
        startPhone: "",
        userId: "",
        contactId: "",
        startContactId: "",
        updating: false
    }
    this.handleChange = this.handleChange.bind(this);
    this.showUpdate = this.showUpdate.bind(this);
    this.updateUser = this.updateUser.bind(this);
    this.deleteUser = this.deleteUser.bind(this);
    this.cancelUser = this.cancelUser.bind(this);
  }

  componentDidMount(){
    this.setState({userName: this.props.userName, userId: this.props.userId, startName: this.props.userName, startPhone: this.props.userPhone, userPhone: this.props.userPhone, userEmail: this.props.userEmail, startUserEmail: this.props.userEmail, contactId: this.props.contactId, startContactId: this.props.contactId, firstName: this.props.firstName, startFirstName: this.props.firstName, lastName: this.props.lastName, startLastName: this.props.lastName});
  }

  handleChange(e){
    this.setState({[e.target.name]:e.target.value});
  }

  showUpdate(){
    this.setState({updating: true});
  }

  updateUser(){
    let value = this.context;
    if(this.state.userName != "" && this.state.userPhone != "" && this.state.userEmail != "" && this.state.contactId != ""){
      this.setState({loading: true});
      axios.post('https://www.arremoteassistance.com/api/v1/contacts/update.php',{updateContactId: this.state.userId, contactPhone: this.state.userPhone, contactName: this.state.userName, contactId: this.state.contactId, contactEmail: this.state.userEmail},{headers: {Authorization: 'Bearer ' + value.jwt}})
      .then(res => {
          this.setState({loading: false, updating: false, startName: this.state.userName, startPhone: this.state.userPhone, startContactId: this.state.contactId, startEmail: this.state.userEmail});
          this.props.updateSuccess();
      }).catch(error => {
        if (error.response) {
          this.setState({loading: false, updating: false, userName: this.state.startName, userPhone: this.state.startPhone, userEmail: this.state.startUserEmail, contactId: this.state.startContactId});
          this.props.updateError();
          if (error.response.status === 401) {
            value.logout()
          }
        } else if (error.request) {
          // The request was made but no response was received
          this.setState({loading: false, updating: false, userName: this.state.startName, userPhone: this.state.startPhone, userEmail: this.state.startUserEmail, contactId: this.state.startContactId});
          this.props.updateError();
        } else {
          // Something happened in setting up the request that triggered an Error
          this.setState({loading: false, updating: false, userName: this.state.startName, userPhone: this.state.startPhone, userEmail: this.state.startUserEmail, contactId: this.state.startContactId});
          this.props.updateError();
        }
      });
    }else{
      this.setState({updating: false, userName: this.state.startName, userPhone: this.state.startPhone, userEmail: this.state.startUserEmail, contactId: this.state.startContactId});
      this.props.updateError();
    }      
  }

  deleteUser(){
    let value = this.context;
    if(this.state.userName != "" && this.state.userRole != ""){
      this.setState({loading: true});
      axios.post('https://www.arremoteassistance.com/api/v1/contacts/delete.php',{deleteContactId: this.state.userId},{headers: {Authorization: 'Bearer ' + value.jwt}})
          .then(res => {
            this.setState({loading: false, updating: false, startName: "", startPhone: "", startContactId: "", startEmail: ""});
            this.props.deleteSuccess();
        }).catch(error => {
            if(error.response.status == 400) {
              this.setState({loading: false, updating: false, userName: this.state.startName, userPhone: this.state.startPhone, userEmail: this.state.startUserEmail, contactId: this.state.startContactId});
              this.props.deleteError(error.response.data.error);
            }else if(error.response.status === 401) {
              value.logout()
            }else{
              this.setState({loading: false, updating: false, userName: this.state.startName, userPhone: this.state.startPhone, userEmail: this.state.startUserEmail, contactId: this.state.startContactId});
              this.props.deleteError(error.response.data.error);
            }
      });
    }else{
      this.setState({userName: this.state.startName, updating: false});
      this.props.deleteError("It looks like some information was missing. Please try again");
    } 
  }

  cancelUser(){
    this.setState({updating: false, userName: this.state.startName, userPhone: this.state.startPhone, contactId: this.state.startContactId, userEmail: this.state.startUserEmail});     
  }

  render(){
    let value = this.context;
    return (
      <div className="userWrapper">
        <div className="userInfoWrapper">
          {this.state.updating ?  
            <div>
            {this.state.loading && 
              <LoadingSpinner />
            }
              <div className="username">
                <input type="text" name="userName" className="companyInput" placeholder="Full Name..." onChange={this.handleChange} value={this.state.userName} />
              </div>
              <div className="contactId">
                <input type="text" name="contactId" className="companyInput" placeholder="Contact Id..." onChange={this.handleChange} value={this.state.contactId} />
              </div>
              <div className="userLicense" name="userLicense">
                <input type="tel" name="userPhone" className="companyInput" placeholder="Phone #..." onChange={this.handleChange} value={this.state.userPhone} />
              </div>
              <div className="userRole" name="userRole">
                <input type="text" name="userEmail" className="companyInput" placeholder="Email..." onChange={this.handleChange} value={this.state.userEmail} />
              </div>
              <div className="userUpdate">
                <Button variant="secondary" onClick={this.cancelUser} >Cancel</Button>
                <Button variant="success" onClick={this.updateUser} >Update</Button>
                <Button variant="danger" onClick={this.deleteUser} >Delete</Button>
              </div>
            </div>
          :
            <div>
              <div className="username" name="userName">
                {this.state.userName}
              </div>
              <div className="contactId">
                {this.state.contactId}
              </div>
              <div className="userLicense" name="userLicense">
                {this.state.userPhone}
              </div>
              <div className="userRole" name="userRole">
                {this.state.userEmail}
              </div>
              <div className="userUpdate">
                <Button variant="primary" onClick={this.showUpdate} >Update</Button>
              </div>
            </div>
          }
        </div>
      </div>
    );
  }
}

export default UserContact;