import React from 'react';
import axios from 'axios';
import {NavLink, Route} from 'react-router-dom';
import {Redirect} from 'react-router';
import { Modal, Button } from 'react-bootstrap';
import {Context} from "../configcontext/ConfigContext";
import './AccountSettings.css';

class AccountSettings extends React.Component{

  static contextType = Context;

  constructor(props) {
    super(props);
      this.state = {
    }
  }

  render(){
    let value = this.context;
    if(value.jwt == ""){
        return(
          <Route>
            <Redirect to="/login" />
          </Route>
        );
    }else{
      if(value.userPermissions.includes("profile:update")){
        return (
          <div className="mainWrapper">
            <div className="mainWindow">
                <div className="pageHeader">
                  ACCOUNT SETTINGS
                </div>
                <div className="plainBody">
                  Account settings coming soon!
                </div>
            </div>
          </div>
        );
    }else{
         return(
            <Route>
              <Redirect to="/dashboard" />
            </Route>
         );
      }
  }
  }
}

export default AccountSettings;