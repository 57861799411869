import React from 'react';
import {NavLink, Route} from 'react-router-dom';
import { Redirect } from 'react-router';
import axios from 'axios';
import Call from '../call/Call';
//import CallsFilter from '../callsfilter/CallsFilter';
import CallsHeader from '../callsheader/CallsHeader';
import {Context} from "../configcontext/ConfigContext";
import LoadingSpinner from '../loadingspinner/LoadingSpinner';
import './Dashboard.css';

class Dashboard extends React.Component{

	static contextType = Context;

	constructor(props) {
    	super(props);
		this.state = {
		    calls: [],
		    filteredCalls: [],
		    searchString: "",
		    searching: false,
		    loading: true,
		    modalBody: "",
		    modalHeader: "",
		    callSortDecending: true,
		    show: false
		}
		this.updateSearch = this.updateSearch.bind(this);
		this.sortDate = this.sortDate.bind(this);
	}

  	componentDidMount() {
	  	let value = this.context;
	    axios.post('https://www.arremoteassistance.com/api/v1/calls/read.php',{},{headers: {Authorization: 'Bearer ' + value.jwt}})
	    .then(res => {
	        const calls = res.data["records"];
	        this.setState({ calls: calls, loading: false });
	    }).catch(error => {
	        if (error.response) {
	        	this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.response.data.error, modalHeader: "Error", show: true});
	        	if (error.response.status === 401) {
		            value.logout()
		        }
		    } else if (error.request) {
		      // The request was made but no response was received
		        this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.request, modalHeader: "Error", show: true});
		    } else {
		      // Something happened in setting up the request that triggered an Error
		        this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.message, modalHeader: "Error", show: true});
		    }
	    });
  	}

  	updateSearch(searchString){
		if(searchString != ""){
		  	const searchResults = this.state.calls.filter(call => 
		  		call.createdUserName.toLowerCase().includes(searchString.toLowerCase()) ||
		  		call.receivedUserName.toLowerCase().includes(searchString.toLowerCase()) ||
		  		call.date.toLowerCase().includes(searchString.toLowerCase())
		  	);
		  	this.setState({searching: true, filteredCalls: searchResults});
		}else{
			this.setState({searching: false, filteredCalls: []});
		}
  	}

  	sortDate(){
  		if(this.state.callSortDecending){
  			const myData = [].concat(this.state.calls).sort((a, b) => a.id < b.id ? 1 : -1);
  			this.setState({callSortDecending: false, calls: myData});
  		}else{
  			const myData = [].concat(this.state.calls).sort((a, b) => a.id > b.id ? 1 : -1);
  			this.setState({callSortDecending: true, calls: myData});
  		}
  	}

  	render(){
	  	let value = this.context;
	    if (value.jwt == "") {
	      return(
				<Route>
					<Redirect to="/login" />
				</Route>
			);
	    }else{
	    	if(value.userPermissions.includes("callhistory:view")){
			    return (
			    	<div className="mainWrapper">
			    		<div className="mainWindow">
				    		{this.state.loading &&
				    			<LoadingSpinner />
				    		}
				    		<CallsHeader updateSearch={this.updateSearch}/>
				      		<div className="projectsFilterWrapper">
						      	<div className="filterId">
						        Call ID <img src='/images/sorticon.png' alt="statusFilter"/>
						        </div>
						        <div className="filterCallMadeBy">
						        Call Made By <img src='/images/sorticon.png' alt="projectNameFilter"/>
						        </div>
						        <div className="filterName">
						        Call Received By <img src='/images/sorticon.png' alt="projectNameFilter"/>
						        </div>
						        <div className="filterDuration">
						        Duration <img src='/images/sorticon.png' alt="locationFilter"/>
						        </div>
						        <div className="filterDate" onClick={this.sortDate}>
						        Date/Time <img src='/images/sorticon.png' alt="locationFilter"/>
						        </div>
						    </div>
				      		{(this.state.searching == false) ? (
					      		this.state.calls.map(call => {
					      			return(
							        	<Call key={call.id} callId={call.id} callDuration={call.duration} callDate={call.date} callCreatedBy={call.createdUserName} callReceivedBy={call.receivedUserName}/>
							        );
					       		})
					       		)
					       		:
					       		(this.state.filteredCalls.map(call => {
					       			return(
							        	<Call key={call.id} callId={call.id} callDuration={call.duration} callDate={call.date} callCreatedBy={call.createdUserName} callReceivedBy={call.receivedUserName}/>
							        );
					       		})
					       		)
					      	}
					    </div>
			       	</div>
			    );
			}else{
				{value.logout()}
				return(
					<Route>
						<Redirect to="/login" />
					</Route>
				);
			}
		}
  	}
}

export default Dashboard;