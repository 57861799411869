import React from 'react';
import {NavLink, Link} from 'react-router-dom';
import {Context} from "../configcontext/ConfigContext";
import './MainHeader.css';

class MainHeader extends React.Component{

  static contextType = Context;
  
  constructor(props) {
    super(props);
    this.state = {
      mobileMenuShow: false,
      aboutSubMenuShow: false
    }
    this.showMobileMenu = this.showMobileMenu.bind(this);
    this.showAboutMobileMenu = this.showAboutMobileMenu.bind(this);
  }

  showMobileMenu(){
    if(this.state.mobileMenuShow == false){
      this.setState({mobileMenuShow: true});
    }else{
      this.setState({mobileMenuShow: false});
    }
  }

  showAboutMobileMenu(){
    if(this.state.aboutSubMenuShow == false){
      this.setState({aboutSubMenuShow: true});
    }else{
      this.setState({aboutSubMenuShow: false});
    }
  }

  render(){
    let value = this.context;
    return(

      <div className="headerSmall">
        <div className="logoSmall">
          <NavLink to="/" activeClassName="">
            <img src={'/images/iconlogofull.png'} alt="ARUtilityLogo"/>
          </NavLink>
        </div>
        <div className="mainMenu">
          <div className="menuOption">
            <NavLink exact={true} to="/" activeClassName="headerActive">
              HOME
            </NavLink>
          </div>
          {/*<div className="menuOption">
            <NavLink exact={true} to="/howitworks" activeClassName="headerActive">
              HOW IT WORKS
            </NavLink>
          </div>*/}
          <div className="menuOption"> 
            <NavLink exact={true} to="/about" activeClassName="headerActive">
              ABOUT
            </NavLink>
            <div className="subMenu">
              <div className="subMenuOption">
                <Link to="/howitworks">How It Works</Link>
              </div>
              <div className="subMenuOption">
                <Link to="/pricing">Pricing</Link>
              </div>
              <div className="subMenuOption">
                <Link to="/solutions">Our Solutions</Link>
              </div>
              <div className="subMenuOption">
                <Link to="/careers">Join Our Team</Link>
              </div>
            </div>
          </div>
          <div className="menuOption">
            <NavLink exact={true} to="/contact" activeClassName="headerActive">
              CONTACT
            </NavLink>
          </div>
          <div className="menuOption">
            <NavLink exact={true} to="/login" activeClassName="headerActive">
              LOGIN
            </NavLink>
          </div>
          <div className="menuRegister">
            <Link to="/account/create">
              <div className="registerButton">
                  SIGN UP
              </div>
            </Link>
          </div>
        </div>
        <div className="mobileMenu" onClick={this.showMobileMenu}>
        </div>
        {this.state.mobileMenuShow &&
          <div className="mobileMenuWrapper">
            <div className="mobileMenuOption">
              <Link to="/" >
                HOME
              </Link>
            </div>
            <div className="mobileMenuOption" onClick={this.showAboutMobileMenu}> 
              {this.state.aboutSubMenuShow ? 
                <div>
                  ABOUT -
                </div>
                :
                <div>
                  ABOUT +
                </div>
              }
              {this.state.aboutSubMenuShow &&
                <div>
                  <Link to="/about" >
                    <div className="mobileMenuSubOption">
                      &nbsp;&nbsp;- ABOUT
                    </div>
                  </Link>
                  <Link to="/howitworks">
                    <div className="mobileMenuSubOption">
                      &nbsp;&nbsp;- HOW IT WORKS
                    </div>
                  </Link>
                  <Link to="/pricing" >
                    <div className="mobileMenuSubOption">
                      &nbsp;&nbsp;- PRICING
                    </div>
                  </Link>
                  <Link to="/solutions" >
                    <div className="mobileMenuSubOption">
                      &nbsp;&nbsp;- OUR SOLUTIONS
                    </div>
                  </Link>
                  <Link to="/careers" >
                    <div className="mobileMenuSubOption">
                      &nbsp;&nbsp;- JOIN OUR TEAM
                    </div>
                  </Link>
                </div>
              }
            </div>
            <div className="mobileMenuOption">
              <Link to="/contact" >
                CONTACT
              </Link>
            </div>
            <div className="mobileMenuOption">
              <Link to="/login">
                LOGIN
              </Link>
            </div>
            <div className="mobileMenuOption">
              <Link to="/account/create">
                REGISTER
              </Link>
            </div>
          </div>
        }
      </div>
    );
  }
}

export default MainHeader;