import React from 'react';
import axios from 'axios';
import {Context} from "../configcontext/ConfigContext";
import LoadingSpinner from '../loadingspinner/LoadingSpinner';
import {Button,Tooltip, OverlayTrigger} from 'react-bootstrap';
import './User.css';

class User extends React.Component{

  static contextType = Context;

  constructor(props) {
    super(props);
      this.state = {
        loading: false,
        userName: "",
        userEmail: "",
        userPhone: "",
        userRole: "",
        userLicense: "",
        firstName: "",
        lastName: "",
        userId: "",
        contactId: "",
        startName: "",
        startRole: "",
        startLicense: "",
        startFirstName: "",
        startLastName: "",
        startPhone: "",
        updating: false,
        userPermissions: [],
        accountPermissions: []
    }
    this.handleChange = this.handleChange.bind(this);
    this.showUpdate = this.showUpdate.bind(this);
    this.updateUser = this.updateUser.bind(this);
    this.deleteUser = this.deleteUser.bind(this);
    this.cancelUser = this.cancelUser.bind(this);
    this.changeRadio = this.changeRadio.bind(this);
  }

  componentDidMount(){
    var accountPermissionsArray;
    var userPermissionsArray;
    if(this.props.accountPermissions != null){
      accountPermissionsArray = this.props.accountPermissions.split(',');
    }else{
      accountPermissionsArray = [];
    }
    if(this.props.userPermissions != null){
      userPermissionsArray = this.props.userPermissions.split(',');
    }else{
      userPermissionsArray = [];
    }
    accountPermissionsArray.sort();
    this.setState({accountPermissions: accountPermissionsArray, userPermissions: userPermissionsArray, userName: this.props.userName, userRole: this.props.userRole, userId: this.props.userId, startName: this.props.userName, startRole: this.props.userRole, startPhone: this.props.userPhone, userPhone: this.props.userPhone, userEmail: this.props.userEmail, contactId: this.props.contactId, firstName: this.props.firstName, startFirstName: this.props.firstName, lastName: this.props.lastName, startLastName: this.props.lastName, startLicense: this.props.userLicense, userLicense: this.props.userLicense});
  }

  handleChange(e){
    this.setState({[e.target.name]:e.target.value});
  }

  showUpdate(){
    this.setState({updating: true});
  }

  updateUser(){
    let value = this.context;
    if(this.state.userName != "" && this.state.userRole != "" && this.state.userPhone != "" && this.state.firstName != "" && this.state.lastName != "" && this.state.contactId != ""){
      this.setState({loading: true});
      axios.post('https://www.arremoteassistance.com/api/v1/users/update.php',{userRole: this.state.userRole, updateUserId: this.state.userId, userPermissions: this.state.userPermissions, userPhone: this.state.userPhone, firstName: this.state.firstName, lastName: this.state.lastName, userLicense: this.state.userLicense},{headers: {Authorization: 'Bearer ' + value.jwt}})
      .then(res => {
          this.setState({loading: false, updating: false, startName: this.state.userName, startRole: this.state.userRole, startFirstName: this.state.firstName, startLastName: this.state.lastName, startPhone: this.state.userPhone, startLicense: this.state.userLicense});
          this.props.updateSuccess();
      }).catch(error => {
        if (error.response) {
          this.setState({loading: false, updating: false, userName: this.state.startName, userRole: this.state.startRole, firstName: this.state.startFirstName, lastName: this.state.startLastName, userPhone: this.state.startPhone, userLicense: this.state.startLicense});
          this.props.updateError();
          if (error.response.status === 401) {
            value.logout()
          }
        } else if (error.request) {
          // The request was made but no response was received
          this.setState({loading: false, updating: false, userName: this.state.startName, userRole: this.state.startRole, firstName: this.state.startFirstName, lastName: this.state.startLastName, userPhone: this.state.startPhone, userLicense: this.state.startLicense});
          this.props.updateError();
        } else {
          // Something happened in setting up the request that triggered an Error
          this.setState({loading: false, updating: false, userName: this.state.startName, userRole: this.state.startRole, firstName: this.state.startFirstName, lastName: this.state.startLastName, userPhone: this.state.startPhone, userLicense: this.state.startLicense});
          this.props.updateError();
        }
      });
    }else{
      this.setState({updating: false, userName: this.state.startName, userRole: this.state.startRole, firstName: this.state.startFirstName, lastName: this.state.startLastName, userPhone: this.state.startPhone, userLicense: this.state.startLicense});
      this.props.updateError();
    }      
  }

  deleteUser(){
    let value = this.context;
    if(this.state.userName != "" && this.state.userRole != ""){
      this.setState({loading: true});
      axios.post('https://www.arremoteassistance.com/api/v1/users/delete.php',{username: this.state.userName, userRole: this.state.userRole, updateUserId: this.state.userId, userLicense: this.state.userLicense},{headers: {Authorization: 'Bearer ' + value.jwt}})
          .then(res => {
            this.setState({loading: false, updating: false, startName: "", startRole: "", startLicense: ""});
            this.props.deleteSuccess();
        }).catch(error => {
            if(error.response.status == 400) {
              this.setState({loading: false, updating: false, userName: this.state.startName, userRole: this.state.startRole, firstName: this.state.startFirstName, lastName: this.state.startLastName, userPhone: this.state.startPhone, userLicense: this.state.startLicense});
              this.props.deleteError(error.response.data.error);
            }else if(error.response.status === 401) {
              value.logout()
            }else{
              this.setState({loading: false, updating: false, userName: this.state.startName, userRole: this.state.startRole, firstName: this.state.startFirstName, lastName: this.state.startLastName, userPhone: this.state.startPhone, userLicense: this.state.startLicense});
              this.props.deleteError(error.response.data.error);
            }
      });
    }else{
      this.setState({userName: this.state.startName, userRole: this.state.startRole, updating: false});
      this.props.deleteError("It looks like some informationw as missing. Please try again");
    } 
  }

  cancelUser(){
    this.setState({updating: false, userName: this.state.startName, userRole: this.state.startRole, firstName: this.state.startFirstName, lastName: this.state.startLastName, userPhone: this.state.startPhone, userLicense: this.state.startLicense});     
  }

  changeRadio(e){
    const index = this.state.userPermissions.indexOf(e.target.id);
    var updatedUserPermissions = this.state.userPermissions;
    if (index > -1) {
      updatedUserPermissions.splice(index, 1);
    }else{
      updatedUserPermissions.push(e.target.id);
    }
    this.setState({userPermissions: updatedUserPermissions});
  }

  render(){
    let value = this.context;
    var roleText;
    if(this.state.userRole == "1"){
      roleText = "Admin";
    }else if(this.state.userRole == "2"){
      roleText = "User";
    }
    return (
      <div className="userWrapper">
        <div className="userInfoWrapper">
          {this.state.updating ?  
            <div>
            {this.state.loading && 
              <LoadingSpinner />
            }
              <div className="username">
                {this.state.userName}
              </div>
              <div className="contactId">
                {this.state.contactId}
              </div>
              <div className="userLicense" name="userLicense">
                <select className="userRoleSelect" name="userLicense" onChange={this.handleChange} value={this.state.userLicense}>
                  <option value=""></option>
                  {(this.state.userLicense != "" && this.state.userLicense != null) &&
                    <option value={this.state.userLicense}>{this.state.userLicense}</option>
                  }
                  {this.props.availableLicenses.map(license => {
                    return(
                      <option value={license.subscription_id}>{license.subscription_id}</option>
                    );
                  })}
                </select>
              </div>
              <div className="userRole">
                <select className="userRoleSelect" name="userRole" onChange={this.handleChange} value={this.state.userRole}>
                    <option value="1">Admin</option>
                    <option value="2">User</option>
                </select>
              </div>
              <div className="userUpdate">
                <Button variant="secondary" onClick={this.cancelUser} >Cancel</Button>
                <Button variant="success" onClick={this.updateUser} >Update</Button>
                <Button variant="danger" onClick={this.deleteUser} >Delete</Button>
              </div>
              <div className="userRolesWrapper">
                <div className="contactInfoWrapper">
                  <font color="#c62828">Phone Number:</font>
                  <br/>
                  <input type="tel" name="userPhone" className="companyInput" placeholder="Phone # (Format xxx-xxx-xxxx)" onChange={this.handleChange} value={this.state.userPhone} />
                  <br/>
                  <font color="#c62828">First Name:</font>
                  <br/>
                  <input type="text" name="firstName" className="companyInput" placeholder="First Name..." onChange={this.handleChange} value={this.state.firstName} />
                  <br/>
                  <font color="#c62828">Last Name:</font>
                  <br/>
                  <input type="text" name="lastName" className="companyInput" placeholder="Last Name..." onChange={this.handleChange} value={this.state.lastName} />
                  <br/>
                </div>
                <b>USER PERMISSIONS</b>
                {this.state.accountPermissions.map(permission => {
                  return(
                    <div className="userRoleWrapper" key={permission}>
                    <input id={permission} type="checkbox" name={permission} checked={this.state.userPermissions.includes(permission)} onChange={this.changeRadio} className="featureSelectedCheckbox"/>
                    {permission}
                    </div>
                  );
                })}
              </div>
            </div>
          :
            <div>
              <div className="username" name="userName">
                {this.state.userName}
              </div>
              <div className="contactId">
                {this.state.contactId}
              </div>
              <div className="userLicense" name="userLicense">
                {(this.state.userLicense == "" || this.state.userLicense == null) ?
                  <div>
                    Unassigned
                  </div>
                  :
                  <div>
                    {this.state.userLicense}
                  </div>
                }
              </div>
              <div className="userRole" name="userRole">
                {roleText}
              </div>
              <div className="userUpdate">
                {value.userPermissions.includes("user:update") ?
                  <Button variant="primary" onClick={this.showUpdate} >Update</Button>
                  :
                  <OverlayTrigger placement="left" overlay={<Tooltip id="tooltip-disabled">You do not have adequate permission to update users</Tooltip>}>
                    <Button variant="secondary">Update</Button>
                  </OverlayTrigger>
                }
              </div>
            </div>
          }
        </div>
      </div>
    );
  }
}

export default User;