import React from 'react';
import axios from 'axios';
import {Context} from "../configcontext/ConfigContext";
import LoadingSpinner from '../loadingspinner/LoadingSpinner';
import {Button,Tooltip, OverlayTrigger} from 'react-bootstrap';
import './ChatContact.css';

class ChatContact extends React.Component{

  static contextType = Context;

  constructor(props) {
    super(props);
      this.state = {
        loading: false,
        userName: "",
        userEmail: "",
        userPhone: "",
        userId: "",
        contactId: ""
    }
    this.updateChat = this.updateChat.bind(this);
  }

  updateChat(){
    this.props.updateChatName(this.state.contactId, this.state.userPhone);
  }

  componentDidMount(){
    this.setState({userName: this.props.userName, userId: this.props.userId, userPhone: this.props.userPhone, userEmail: this.props.userEmail, contactId: this.props.contactId});
  }

  render(){
    let value = this.context;
    return ( 
      <div className="userWrapper">
        <div className="userInfoWrapper" onClick={this.updateChat}>
          <div className="username" name="userName">
            {this.state.userName}
          </div>
          <div className="contactId">
            {this.state.contactId}
          </div>
          <div className="userLicense" name="userLicense">
            {this.state.userPhone}
          </div>
          <div className="userRole" name="userRole">
            {this.state.userEmail}
          </div>
        </div>
      </div>
    );
  }
}

export default ChatContact;