import React from 'react';
import './CallsHeader.css';

class CallsHeader extends React.Component{

  constructor(props) {
    super(props);
    this.state = {
      searchString: ""
    }
    this.updateSearch = this.updateSearch.bind(this);
  }

  updateSearch(e){
    this.setState({searchString: e.target.value});
    this.props.updateSearch(e.target.value);
  }

  render(){
    return (
    	<div className="projectsHeaderWrapper">
      		<div className="projectsHeaderText">
          Call History
          </div>
          <div className="projectsHeaderSearch">
            <input type="text" className="projectsHeaderSearch" id="projectsHeaderSearch" placeholder="Search..." value={this.state.searchString} onChange={this.updateSearch} />
          </div>
      </div>
    );
  }
}

export default CallsHeader;