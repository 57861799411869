import React from 'react';
import {NavLink} from 'react-router-dom';
import {Context} from "../configcontext/ConfigContext";
import './UserMenuOption.css';

class UserMenuOption extends React.Component{

  static contextType = Context;
  
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  render(){
    let value = this.context;
    if(this.props.text == "Logout"){
      return(
        <div className="userMenuOption" onClick={() => {value.logout()}}>
          {this.props.text}
        </div>
        
      );
    }else{
      return(
        <NavLink activeClassName="" to={this.props.goto}> 
          <div className="userMenuOption">
            {this.props.text}
          </div>
        </NavLink>
      );
    }
  }
}

export default UserMenuOption;