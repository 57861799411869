import React from 'react';
import axios from 'axios';
import MainHeader from '../mainheader/MainHeader';
import MainFooter from '../mainfooter/MainFooter';
import {Context} from "../configcontext/ConfigContext";
import {Link} from 'react-router-dom';
import './About.css';

class About extends React.Component{

  static contextType = Context;

  constructor(props) {
    super(props);
      this.state = {
    }
  }

  render(){
    let value = this.context;
    return (
      <div>
        <MainHeader />
        <div className="mainVideoWrapper">
          <div className="heroContentAbout">
            <div className="heroContentHeader">
              <b>AUGMENTED REALITY<br/>REMOTE ASSISTANCE</b>
            </div>
            <br/>
            <div className="heroContentBody">
            Get help when you need it, where you need it<br/>
            from anywhere in the world
            </div>
            <Link to="/account/create">
              <button className="trialButton" name="freeTrial">Start Your Free Trial</button>
            </Link>
          </div>
          <div className="videoOverlay">
          </div>
          <video preload="auto" id="video" autoPlay={true} muted={true} loop={true}>
            <source src="/images/homepreview.mp4" type="video/mp4" />
          </video>
        </div>
        <div className="mainPageExplanation">
          <font size="6"><b>UPGRADE YOUR WORKFLOW</b></font>
          <div className="mainPageExplanationBody">
          AR Remote Assistance augmented reality solution helps your teams take a customer-first approach to business tasks like quotes, retail consultations, troubleshooting, warranties, returns, service and more.
          </div>
        </div>
        <div className="howitworksIndustryWrapper" onClick={this.handleOnClick} id="focusUtilities">
          <div className="industryWrapper" id="focusUtilities">
            <div className="industryWrapperImg" id="focusUtilities">
              <img src="/images/about1.png" alt="Utilities" id="focusUtilities"/>
            </div>
          </div>
          <div className="industryWrapper" onClick={this.handleOnClick} id="focusUtilityLocators">
            <div className="industryWrapperImg" id="focusUtilities">
              <img src="/images/about2.png" alt="Utilities" id="focusUtilities"/>
            </div>
          </div>
          <div className="industryWrapper" onClick={this.handleOnClick} id="focusContractors">
            <div className="industryWrapperImg" id="focusUtilities">
              <img src="/images/about3.png" alt="Utilities" id="focusUtilities"/>
            </div>
          </div>
        </div>
        <div className="pageTitle">
          ARUTILITY'S CORE VALUES
        </div>
        <div className="pageDescription">
          At ARUtility we are focused on not just creating great solutions that bring value to your organization. Our team is also focused on creating a diverse work force, providing top customer service and fostering innovation and creativity. Some of our core values include:
          <br/>
          <br/>
          <ul>
              <li>Promote and Encourage a Diverse Workplace</li>
              <li>Commitment to Customer Satisfaction</li>
              <li>Commitment to Innovation and Excellence</li>
              <li>Commitment to Accountability</li>
              <li>Results Oriented</li>
              <li>Integrity</li>
              <li>Teamwork</li>
          </ul>
        </div>
        <div className="pageTitle">
          ARUTILITY LEADERSHIP
        </div>
        <div className="pageDescription">
          <div className="teamWrapper">
            <div className="teamMemberOdd">
              <div className="teamMemberImage">
                <img src="/images/headshot_JoeEastman2.png" alt="Joe Eastman Photo" />
              </div>
              <div className="teamMemberDesc">
                <b><font size="5">Joe Eastman, P.E.</font></b><br/>
                <i>Founder/CEO</i>
                <br/>
                <br/>
                Joe is a licensed Professional Engineer in the State of Michigan and the Founder of ARUtility. Joe graduated from the University of Michigan with a degree in Civil Engineering. He has over 13 years of experience in the Civil Engineering field that ranges from water main, gas main, electric, storm water, sanitary sewer installations and more. This experience includes many years of field inspection. His skills also include extensive experience with modeling of water and sewer systems and GIS editing. Joe also holds an S1 license which is the highest license that can be obtained through the State of Michigan allowing him to operate any public water system within the state.
                <br/>
                <br/>
                He started ARUtility based on his own wants and needs from years of working on construction projects of all types. In particular, the idea for an augmented reality utility locating application came from one memorable experience. While performing inspection services for a water main installation, he witnessed a contractor who struck an underground gas line. The ground surface had been removed and the contractor lost track of where the utilities were located. The buried line began spewing natural gas and the contractor quickly exited the excavator and ran away to safety. The damage resulted in the evacuation of multiple blocks and cost the contractor tens of thousands of dollars. It was this experience in particular that was the ah-ha moment. ARUtility was born on that day.
                <br/>
              </div>
            </div>
            <div className="teamMemberOdd">
              <div className="teamMemberImage">
                <img src="/images/Alando.png" alt="Joe Eastman Photo" />
              </div>
              <div className="teamMemberDesc">
                <b><font size="5">Alando Chappell</font></b><br/>
                <i>Managing Partner & COO</i>
                <br/>
                <br/>
                Alando brings to the team almost 20 years of experience in the utility industry. He began his career as a Journey Line Worker for over 10 years and gained exposure to field operations through that experience. He quickly moved up into a Management position and was the Manager of Water, Steam & Chilled Water Distribution for a utility in Michigan. Alando holds an S1 license which is the highest license that can be obtained through the State of Michigan allowing him to operate any public water system within the state. Alando also received his B.S. in Organizational Management and brings his excellent management style and techniques to the team.
                <br/>
              </div>
            </div>
          </div>
        </div>
        <div className="footerSpace">
        </div>
        <MainFooter />
      </div>
    );
  }
}

export default About;