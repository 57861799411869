import React from 'react';
import axios from 'axios';
import {NavLink, Route} from 'react-router-dom';
import {Redirect} from 'react-router';
import { Modal, Button } from 'react-bootstrap';
import LoadingSpinner from '../loadingspinner/LoadingSpinner';
import {Context} from "../configcontext/ConfigContext";
import { ResponsiveLine } from '@nivo/line';
import './Analytics.css';

class Analytics extends React.Component{

  static contextType = Context;

  constructor(props) { 
    super(props);
      this.state = {
        callAnalytics: [],
        rawAnalytics: [],
        dateFilter: "",
        show: false,
        modalBody: "",
        modalHeader: "",
        test: "",
        loading: true
    }
    this.filterDate = this.filterDate.bind(this);
    this.showModal = this.showModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  componentDidMount(){
    let value = this.context;
    axios.post('https://www.arremoteassistance.com/api/v1/calls/analytics.php',{},{headers: {Authorization: 'Bearer ' + value.jwt}})
    .then(res => {
        const calls = res.data["records"];
        var d = new Date();
        var n = d.getFullYear();
        var numRecords = calls.length;
        for(var i=0; i < calls.length; i++){
          if(calls[i].year == n){
            const callArray = [];
            callArray.push(calls[i]);
            this.setState({callAnalytics: callArray, dateFilter: n});
          }
        }
        this.setState({ rawAnalytics: calls, loading: false });
    }).catch(error => {
        if (error.response) {
          this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.response.data.error, modalHeader: "Error", show: true});
          if (error.response.status === 401) {
            value.logout()
          }
      } else if (error.request) {
        // The request was made but no response was received
          this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.request, modalHeader: "Error", show: true});
      } else {
        // Something happened in setting up the request that triggered an Error
          this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.message, modalHeader: "Error", show: true});
      }
    });
  }

  showModal(){
    this.setState({show: true});
  }

  closeModal(){
    this.setState({show: false});
  }

  filterDate(e){
    var yearFilter = e.target.getAttribute("data-year");
    const callArray = [];
    for(var i=0; i < this.state.rawAnalytics.length; i++){
      if(this.state.rawAnalytics[i].year == yearFilter){
        callArray.push(this.state.rawAnalytics[i]);
      }
    }
    this.setState({callAnalytics: callArray, dateFilter: yearFilter});
  }

  render(){
    let value = this.context;
    const{show} = this.state;
    if(value.jwt == ""){
        return(
          <Route>
            <Redirect to="/login" />
          </Route>
        );
    }else{
      if(value.userPermissions.includes("analytics:view")){
        return (
          <div className="mainWrapper">
            <Modal show={show} onHide={this.closeModal}>
              <Modal.Header closeButton>
                <Modal.Title>{this.state.modalHeader}</Modal.Title>
              </Modal.Header>
              <Modal.Body>{this.state.modalBody}</Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={this.closeModal}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
            <div className="mainWindow">
                {this.state.loading &&
                  <LoadingSpinner />
                }
                <div className="pageHeader">
                  ANALYTICS
                </div>
                <div className="analyticsFilters">
                  {this.state.rawAnalytics.map(date => {
                      return(
                        <Button key={date.year} variant="primary" data-year={date.year} onClick={this.filterDate}>
                        {date.year}
                        </Button>
                      );
                    })
                  }
                </div>
                <div className="analyticsWrapper">
                  <ResponsiveLine
                    data={this.state.callAnalytics}
                    margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
                    xScale={{ type: 'point' }}
                    yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: true, reverse: false }}
                    yFormat=" >-.2f"
                    curve="linear"
                    axisTop={null}
                    enableArea={true}
                    areaOpacity={0.4}
                    enablePointLabel={true}
                    axisRight={null}
                    axisBottom={{
                        orient: 'bottom',
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: this.state.dateFilter,
                        legendOffset: 36,
                        legendPosition: 'middle'
                    }}
                    axisLeft={{
                        orient: 'left',
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: 'Minutes',
                        legendOffset: -40,
                        legendPosition: 'middle'
                    }}
                    colors={{ scheme: 'set1' }}
                    pointSize={10}
                    pointColor={{ theme: 'background' }}
                    pointBorderWidth={2}
                    pointBorderColor={{ from: 'serieColor' }}
                    pointLabelYOffset={-12}
                    useMesh={true}
                    legends={[
                        {
                            anchor: 'bottom-right',
                            direction: 'column',
                            justify: false,
                            translateX: 100,
                            translateY: 0,
                            itemsSpacing: 0,
                            itemDirection: 'left-to-right',
                            itemWidth: 80,
                            itemHeight: 20,
                            itemOpacity: 0.75,
                            symbolSize: 12,
                            symbolShape: 'circle',
                            symbolBorderColor: 'rgba(0, 0, 0, .5)',
                            effects: [
                                {
                                    on: 'hover',
                                    style: {
                                        itemBackground: 'rgba(0, 0, 0, .03)',
                                        itemOpacity: 1
                                    }
                                }
                            ]
                        }
                    ]}
                />
                </div>
            </div>
          </div>
        );
    }else{
         return(
            <Route>
              <Redirect to="/dashboard" />
            </Route>
         );
      }
  }
  }
}

export default Analytics;