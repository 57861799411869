import React from 'react';
import axios from 'axios';
import MainHeader from '../mainheader/MainHeader';
import MainFooter from '../mainfooter/MainFooter';
import {Context} from "../configcontext/ConfigContext";
import { Modal, Button } from 'react-bootstrap';
import LoadingSpinner from '../loadingspinner/LoadingSpinner';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Solutions.css';

class Solutions extends React.Component{

  static contextType = Context;

  constructor(props) {
    super(props);
      this.state = {
    }
  }

  render(){
    let value = this.context;
    const {show} = this.state;
    return (
      <div>
        <MainHeader />
        <Modal show={show} onHide={this.closeModal}>
          <Modal.Header closeButton>
            <Modal.Title>{this.state.modalHeader}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{this.state.modalBody}</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.closeModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        <div className="pageTitle">
          OUR SOLUTIONS
        </div>
        <div className="pageDescription">
          <div class="solutionPageExplanation">
            Our solutions leverage advanced technology, proprietary data, and deep expertise to help clients in new and exciting ways
          </div>
          <div className="solutionFullWrapper">
            <div className="solutionFullWrapperImage">
              <img src="/images/arutilityview.png" alt="ARUtility Utility Locating and Asset Management"/>
            </div>
            <div className="solutionTextWrapper">
              <div className="solutionWrapperTitle">
                ARUtility Utility Locating and Asset Management
              </div>
              <div className="solutionDescription">
                The ARUtility Utility Locating and Asset Management solution is our flagship solution. By providing field staff with an augmented reality visual of where assets are located at all times, we can increase efficiency, increase safety and decrease damages throughout the entire project lifecycle. 
                <br/>
                <br/>
                Included in our Utility locating and asset management solution is the ability to visualize BIM models in their real-world locations using augmented reality. No other solution on the market allows you to visualize BIM models with utility information under one solution. This allows designers, developers, engineers and more to make more informed decisions.
              </div>
            </div>
            <div className="downloadWrapper">
              <a href="https://play.google.com/store/apps/details?id=com.ARUtility.ARUtility" target="_blank"><img src="/images/googlePlayBlack.png" alt="Download on Google Play"/></a><a href="https://apps.apple.com/us/app/id1493590181" target="_blank"><img src="/images/appleStoreBlack.png" alt="Download on Apple App Store"/></a>
            </div>
          </div>
          <div className="solutionWrapper">
            <div className="solutionWrapperImage">
              <img src="/images/remoteassistancesolution.png" alt="ARUtility Remote Assistance"/>
            </div>
            <div className="solutionTextWrapper">
              <div className="solutionWrapperTitle">
                ARUtility Remote Assistance
              </div>
              <div className="solutionDescription">
                Our ARUtility remote assistance solution allows field crews to connect with office staff from anywhere and anytime. We've taken voice and video chat to the next level by adding augmented reality annotations, drawing tools and more. This allows you to get answers out to the field in a more efficient and safe manner.
              </div>
            </div>
            <div className="downloadWrapper">
              <a href="https://play.google.com/store/apps/details?id=com.ARUtility.RemoteAssistance" target="_blank"><img src="/images/googlePlayBlack.png" alt="Download on Google Play"/></a><a href="https://apps.apple.com/us/app/id1528561830" target="_blank"><img src="/images/appleStoreBlack.png" alt="Download on Apple App Store"/></a>
            </div>
          </div>
          <div className="solutionWrapper">
            <div className="solutionWrapperImage">
              <img src="/images/remoteuser.png" alt="ARUtility Remote Assistance"/>
            </div>
            <div className="solutionTextWrapper">
              <div className="solutionWrapperTitle">
                Remote Assistance Standalone
              </div>
              <div className="solutionDescription">
                Our AR remote assistance solution allows anyone, from anywhere, at any time, to connect via video and voice. We've taken voice and video communication to the next level with our added augmented reality tools. Leave annotations, drawings and notes that stick in the real-world. Allowing you to more effectively assist your friends, family and colleagues from anywhere in the world.
              </div>
            </div>
            <div className="downloadWrapper">
              <a href="https://play.google.com/store/apps/details?id=com.ARUtility.RemoteAssistanceStandalone" target="_blank"><img src="/images/googlePlayBlack.png" alt="Download on Google Play"/></a><a href="https://apps.apple.com/us/app/id1529740112" target="_blank"><img src="/images/appleStoreBlack.png" alt="Download on Apple App Store"/></a>
            </div>
          </div>
          <div className="solutionWrapper">
            <div className="solutionWrapperImage">
              <img src="/images/arenvironment.png" alt="AREnvironment"/>
            </div>
            <div className="solutionTextWrapper">
              <div className="solutionWrapperTitle">
                AR Environment 3D Scanner
              </div>
              <div className="solutionDescription">
                Our AR Environment solution allows builders, architects, designers, contractors and more to quickly and easily scan their real-world environment using a LiDar enabled device. These scans can quickly be exported out to a 3D model to then be imported into CAD or 3D modeling software. Designers can then make construction decisions, take measurements and more using the 3D model.
              </div>
            </div>
            <div className="downloadWrapper">
              <a href="https://apps.apple.com/us/app/id1516489416" target="_blank"><img src="/images/appleStoreBlack.png" alt="Download on Apple App Store"/></a>
            </div>
          </div>
          <div className="solutionWrapper">
            <div className="solutionWrapperImage">
              <img src="/images/fieldgo.png" alt="FieldGO"/>
            </div>
            <div className="solutionTextWrapper">
              <div className="solutionWrapperTitle">
                ARUtility FieldGO
              </div>
              <div className="solutionDescription">
                This is our integration with the <a href="https://www.fieldgo.com">FieldGO</a> workforce management solution. We've brought augmented reality visuals to FieldGO's existing workforce management solution! Users can seemlessly enter an augmented reality view straight from a dispatched work order. This provides field crews with a better understanding of the assets around them by providing a more intuitive view using augmented reality.
              </div>
            </div>
            <div className="downloadWrapper">
              <a href="https://play.google.com/store/apps/details?id=com.ARUtility.ARUtilityFieldGO" target="_blank"><img src="/images/googlePlayBlack.png" alt="Download on Google Play"/></a><a href="https://apps.apple.com/us/app/id1515994408" target="_blank"><img src="/images/appleStoreBlack.png" alt="Download on Apple App Store"/></a>
            </div>
          </div>
        </div>
        <div className="footerSpace">
        </div>
        <MainFooter />
      </div>
    );
  }
}

export default Solutions;