import React from 'react';
import {NavLink} from 'react-router-dom';
import {Context} from "../configcontext/ConfigContext";
import './Leftrail.css';

class Leftrail extends React.Component{

  static contextType = Context;

  constructor(props) {
    super(props);
    this.state = {
      showFullMenu: false,
      showBillingPopup: true
    }
    this.showFullMenu = this.showFullMenu.bind(this);
    this.hideFullMenu = this.hideFullMenu.bind(this);
    this.closeBillingPopup = this.closeBillingPopup.bind(this);
  }

  showFullMenu(){
    this.setState({showFullMenu: true});
  }

  hideFullMenu(){
    this.setState({showFullMenu: false});
  }

  closeBillingPopup(){
    this.setState({showBillingPopup: false});
  }

  render(){
    let value = this.context;
    const parent = value;
    if (value.jwt == "") {
      return null;
    } else {
        return (
        <div className={this.state.showFullMenu ? "fullLeftRail":"leftRail"}>
          {value.accountTrial == 1 ?
            this.state.showFullMenu ?
              ""
              :
              this.state.showBillingPopup ?
                <div className="popover bs-popover-right" id="popover-basic">
                  <div className="arrow">
                  </div>
                  <h3 className="popover-header">Want Unlimited Voice and Video?
                    <div className="closeBillingPopup" onClick={this.closeBillingPopup}>
                      <img src="/images/close.png" alt="Close Popup" />
                    </div>
                  </h3>
                  <div className="popover-body">Head over to billing to add a license and access unlimited voice and video chat
                  </div>
                </div>
                :
                ""
            :
            ""
          }
          <div className="leftRailMenu" onMouseEnter={this.showFullMenu} onMouseLeave={this.hideFullMenu}>
            {value.userPermissions.includes("callhistory:view") &&
              <NavLink to="/dashboard">
                <div className={this.state.showFullMenu ? "childActiveFull":"childActiveSmall"}>
                  <img src={'/images/callLogIcon.png'} alt="Show Call History"/> 
                  {this.state.showFullMenu ?
                    <div className="menuOptionText">
                      Call History
                    </div>
                    :
                    ""
                  }
                </div>
              </NavLink>
            }
            {value.userPermissions.includes("webremote:view") &&
              <NavLink to="/remotehelp">
                <div className={this.state.showFullMenu ? "childActiveFull":"childActiveSmall"}>
                  <img src={'/images/chatBtn.png'} alt="Web Remote Assistance"/>
                  {this.state.showFullMenu ?
                    <div className="menuOptionText">
                      Start Remote Session
                    </div>
                    :
                    ""
                  }
                </div>
              </NavLink> 
            }
            {value.userPermissions.includes("analytics:view") &&
              <NavLink to="/analytics">
                <div className={this.state.showFullMenu ? "childActiveFull":"childActiveSmall"}>
                  <img src={'/images/analytics.png'} alt="Analytics"/>
                  {this.state.showFullMenu ?
                    <div className="menuOptionText">
                      Usage Analytics
                    </div>
                    :
                    ""
                  }
                </div>
              </NavLink> 
            }
            {value.userPermissions.includes("users:view") &&
              <NavLink to="/users/view">
                <div className={this.state.showFullMenu ? "childActiveFull":"childActiveSmall"}>
                  <img src={'/images/users.png'} alt="Manage Users"/>
                  {this.state.showFullMenu ?
                    <div className="menuOptionText">
                      Manage Users
                    </div>
                    :
                    ""
                  }
                </div>
              </NavLink> 
            }
            {value.userPermissions.includes("billing:update") &&
              <NavLink to="/billing">
                <div className={this.state.showFullMenu ? "childActiveFull":"childActiveSmall"}>
                  <img src={'/images/billing.png'} alt="Manage Billing"/>
                  {this.state.showFullMenu ?
                    <div className="menuOptionText">
                      Manage Billing
                    </div>
                    :
                    ""
                  }
                </div>
              </NavLink> 
            }
            <NavLink to="/contacts">
              <div className={this.state.showFullMenu ? "childActiveFull":"childActiveSmall"}>
                <img src={'/images/contacts.png'} alt="Contacts"/>
                {this.state.showFullMenu ?
                  <div className="menuOptionText">
                    Manage Contacts
                  </div>
                  :
                  ""
                }
              </div>
            </NavLink> 
            <div className={this.state.showFullMenu ? "logoutLeftMenuFull":"logoutLeftMenuSmall"} onClick={() => {value.logout()}}>
              <div className="childActive">
                <img src={'/images/logout.png'} alt="Web Remote Assistance"/>
                {this.state.showFullMenu ?
                  <div className="menuOptionText">
                    Logout
                  </div>
                  :
                  ""
                }
              </div>
            </div> 
          </div>
        </div>
      );
    }
  }
}

export default Leftrail;