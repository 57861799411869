import React from 'react';
import axios from 'axios';
import {Link} from 'react-router-dom';
import MainHeader from '../mainheader/MainHeader';
import MainFooter from '../mainfooter/MainFooter';
import LoadingSpinner from '../loadingspinner/LoadingSpinner';
import { Modal, Button } from 'react-bootstrap';
import './ForgotPassword.css';

class ForgotPassword extends React.Component{

  constructor(props) {
    super(props);
    this.state = {
      email: "",
      show: false,
      modalBody: "",
      modalHeader: "",
      loading: false
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.showModal = this.showModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  componentDidMount() {
  
  }

  handleSubmit(e){
    e.preventDefault();
    var email = this.state.email;
    this.setState({loading: true});
    if(email != ""){
      axios.post('https://www.arremoteassistance.com/api/v1/users/forgotpassword.php',{email})
        .then(res => {
          this.setState({loading: false, modalBody: res.data.message, modalHeader: "Success", show: true});
        }).catch(error => {
          if (error.response) {
              this.setState({loading: false, modalBody: error.response.data.message, modalHeader: "Error", show: true});
          } else if (error.request) {
            // The request was made but no response was received
              this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.request, modalHeader: "Error", show: true});
          } else {
            // Something happened in setting up the request that triggered an Error
              this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.message, modalHeader: "Error", show: true});
          }
        });
    }else{
      this.setState({loading: false, modalBody: "Sorry, it looks like you're missing some information", modalHeader: "Error", show: true});
    }
  }

  handleChange(e) {
    this.setState({[e.target.id]: e.target.value});
  }

  showModal(){
    this.setState({show: true});
  }

  closeModal(){
    this.setState({show: false});
  }

  render(){
    const{show} = this.state;
    return (
      <div className="mainWrapper">
        {this.state.loading &&
          <LoadingSpinner />
        }
        <Modal show={show} onHide={this.closeModal}>
          <Modal.Header closeButton>
            <Modal.Title>{this.state.modalHeader}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{this.state.modalBody}</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.closeModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        <MainHeader />
      	<div className="pageTitle">
        FORGOT PASSWORD
        </div>
        <div className="loginFormWrapper">
            <div className="loginForm">
              <input type="email" id="email" name="email" placeholder="Email..." value={this.state.email} onChange={this.handleChange}/>
              <br/>
              <button value="Submit" onClick={this.handleSubmit}>RESET PASSWORD</button>
              <br/>
              <a href="https://www.arremoteassistance.com/login">
                Back to Login
              </a>
              &nbsp;&nbsp;|&nbsp;&nbsp;
              <Link to="/account/create">
                Register New Account
              </Link>
            </div>
        </div>
        <div className="footerSpace">
        </div>
        <MainFooter />
      </div>
    );
  }
}

export default ForgotPassword;