import React from 'react';
import axios from 'axios';
import {Redirect} from 'react-router';
import {Route, Link} from 'react-router-dom';
import LoadingSpinner from '../loadingspinner/LoadingSpinner';
import {Context} from "../configcontext/ConfigContext";
import { Modal, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './CreateUser.css';

class CreateUser extends React.Component{

	static contextType = Context;

	constructor(props) {
		super(props);
			this.state = {
		    loading: true,
		    userEmail: "",
		    userPhone: "",
		    userRole: 2,
		    userPassword1: "",
		    userPassword2: "",
		    modalBody: "",
		    firstName: "",
		    lastName: "",
		    modalHeader: "",
		    show: false,
		    accountPermissions: [],
		    userPermissions: []
		}
		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.submitForm = this.submitForm.bind(this);
		this.showModal = this.showModal.bind(this);
		this.closeModal = this.closeModal.bind(this);
		this.changeRadio = this.changeRadio.bind(this);
	}

	componentDidMount(){
		let value = this.context;
		var accountPermissionsArray;
	    if(value.accountPermissions != null && value.accountPermissions != ""){
	        accountPermissionsArray = value.accountPermissions.split(',');
	    }else{
	        accountPermissionsArray = [];
	    }
	    accountPermissionsArray.sort();
		this.setState({loading: false, accountPermissions: accountPermissionsArray});
	}

  	handleChange(e){
  		this.setState({[e.target.name]:e.target.value});
  	}

  	changeRadio(e){
      const index = this.state.userPermissions.indexOf(e.target.id);
      var updatedUserPermissions = this.state.userPermissions;
      if (index > -1) {
        updatedUserPermissions.splice(index, 1);
      }else{
        updatedUserPermissions.push(e.target.id);
      }
      this.setState({userPermissions: updatedUserPermissions});
  	}

  	submitForm(){
  		let value = this.context;
  		this.setState({loading: true});
  		axios.post('https://www.arremoteassistance.com/api/v1/users/create.php',{userRole: this.state.userRole, userPermissions: this.state.userPermissions, userEmail: this.state.userEmail, userPhone: this.state.userPhone, userPassword: this.state.userPassword1, firstName: this.state.firstName, lastName: this.state.lastName},{headers: {Authorization: 'Bearer ' + value.jwt}})
      		.then(res => {
       			this.setState({modalBody: "User Successfully Created", modalHeader: "Success", show: true, loading: false});
    		}).catch(error => {
      			if (error.response) {
	        	this.setState({loading: false, modalBody: error.response.data.error, modalHeader: "Error", show: true});
	        	if (error.response.status === 401) {
		            value.logout()
		         }
		    } else if (error.request) {
		      // The request was made but no response was received
		        this.setState({loading: false, modalBody: error.request, modalHeader: "Error", show: true});
		    } else {
		      // Something happened in setting up the request that triggered an Error
		        this.setState({loading: false, modalBody: error, modalHeader: "Error", show: true});
		    }
  		});
  	}

  	handleSubmit(){
  		let value = this.context;
  		if(this.state.userRole != "" && this.state.userPermissions != "" && this.state.userEmail != "" && this.state.userPhone != "" && this.state.firstName != "" && this.state.lastName != ""){
			this.submitForm();
		}else{
			this.setState({ modalBody: "Sorry, it looks like some required information is missing. Please try again.", modalHeader: "Error", show: true});
		}
  	}

  	showModal(){
    	this.setState({show: true});
  	}

  	closeModal(){
    	this.setState({show: false});
  	}

  	render(){
	  	let value = this.context;
	  	const {loading, show} = this.state;
	  	if(value.jwt == ""){
	      return(
	        <Route>
	          <Redirect to="/login" />
	        </Route>
	      );
	    }else{
		    if(value.userPermissions.includes("user:create")){
			    return (
			   		<div className="mainWrapper">
			    		<div className="mainWindow">
				   			{this.state.loading && 
				   				<LoadingSpinner />
				   			}
				   			<Modal show={show} onHide={this.closeModal}>
					            <Modal.Header closeButton>
					              <Modal.Title>{this.state.modalHeader}</Modal.Title>
					            </Modal.Header>
					            <Modal.Body>{this.state.modalBody}</Modal.Body>
					            <Modal.Footer>
					              <Button variant="secondary" onClick={this.closeModal}>
					                Close
					              </Button>
					            </Modal.Footer>
					        </Modal>
					      	<div className="pageHeader">
					      	CREATE NEW USER
					      		<div className="userCreateClose">
						      		<Link to="/users/view">
						      			CLOSE
						      		</Link>
					      		</div>
					      	</div>
					      	<div className="whiteWrapperBody">
						      	<div id="createUserForm">
									<font color="#c62828">Role:</font>
						            <br/>				            
									<select className="userRoleSelect" name="userRole" onChange={this.handleChange} value={this.state.userRole}>
						                <option value="1">Admin</option>
						                <option value="2">User</option>
						            </select>
						            <br/>
						            <br/>
						            <font color="#c62828">Email:</font>
						            <br/>
						        	<input type="email" name="userEmail" id="userEmail" className="companyInput" placeholder="Email..." value={this.state.userEmail} onChange={this.handleChange} />			        
						        	<br/>
						        	<br/>
						        	<font color="#c62828">Phone Number:</font>
						        	<br/>
						        	<input type="tel" pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" name="userPhone" id="userPhone" className="companyInput" placeholder="Phone # (Format: xxx-xxx-xxxx)" value={this.state.userPhone} onChange={this.handleChange} />	
					        		<br/>
						        	<br/>
						        	<font color="#c62828">First Name:</font>
						        	<br/>
						        	<input type="text" name="firstName" id="firstName" className="companyInput" placeholder="First Name..." value={this.state.firstName} onChange={this.handleChange} />	
					        		<br/>
						        	<br/>
						        	<font color="#c62828">Last Name:</font>
						        	<br/>
						        	<input type="text" name="lastName" id="lastName" className="companyInput" placeholder="Last Name..." value={this.state.lastName} onChange={this.handleChange} />	
					        		<br/>
						        	<br/>
					        		<font color="#c62828">Temporary Password:</font>
						            <br/>
						        	<input type="password" name="userPassword1" id="userPassword1" className="companyInput" placeholder="Temporary Password..." value={this.state.userPassword1} onChange={this.handleChange} />			        
						        	<br/>
						        	<br/>
						        	<font color="#c62828">Verify Password:</font>
						            <br/>
						        	<input type="password" name="userPassword2" id="userPassword2" className="companyInput" placeholder="Verify Password..." value={this.state.userPassword2} onChange={this.handleChange} />			        
						        	<br/>
						        </div>
					        	<br/>
					        	<b>USER PERMISSIONS</b>
					            {this.state.accountPermissions.map(permission => {
					                return(
					                	<div className="userRoleWrapper" key={permission}>
					                		<input id={permission} type="checkbox" name={permission} checked={this.state.userPermissions.includes(permission)} onChange={this.changeRadio} className="featureSelectedCheckbox"/>
					                  		{permission}
					                  	</div>
					                );
					            })}
					        	<button id="submitUserCreate" className="submitRedBtn" onClick={this.handleSubmit} >CREATE USER</button>
					      	</div>
					    </div>
			      	</div>
			    );
			}else{
		       return(
		         	<Route>
		            	<Redirect to="/dashboard" />
		          	</Route>
		       );
		    }
	  	}
  	}
}

export default CreateUser;