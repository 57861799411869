import React from 'react';
import axios from 'axios';
import {NavLink, Route} from 'react-router-dom';
import {Redirect} from 'react-router';
import LoadingSpinner from '../loadingspinner/LoadingSpinner';
import {Context} from "../configcontext/ConfigContext";
import UserContact from "../usercontact/UserContact";
import { Modal, Button, Tooltip, OverlayTrigger } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './ManageContacts.css';

class ManageContacts extends React.Component{

	static contextType = Context;

	constructor(props) {
		super(props);
			this.state = {
		    loading: true,
		    contacts: [],
		    filteredContacts: [],
		    modalBody: "",
		    availableLicenses: [],
		    modalHeader: "",
		    searchString: "",
		    searching: false,
		    show: false
		}
		this.changeRadio = this.changeRadio.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.showModal = this.showModal.bind(this);
		this.closeModal = this.closeModal.bind(this);
		this.updateUserSucccess = this.updateUserSucccess.bind(this);
		this.deleteUserSuccess = this.deleteUserSuccess.bind(this);
		this.updateUserError = this.updateUserError.bind(this);
		this.deleteUserError = this.deleteUserError.bind(this);
		this.updateSearch = this.updateSearch.bind(this);
	}

  	componentDidMount() {
	  	let value = this.context;
	    axios.post('https://www.arremoteassistance.com/api/v1/contacts/read.php',{},{headers: {Authorization: 'Bearer ' + value.jwt}})
	      .then(res => {
			this.setState({loading: false, contacts: res.data.message});
	    }).catch(error => {
		    if(error.response){
		      	if(error.response.status == 401){
		        	this.setState({loading: false});
            		value.logout()
		      	}else{
		        	this.setState({modalBody: "Sorry, we were unable to retrieve contacts. Please try again.", modalHeader: "Error", show: true, loading: false});
		      	}
		    }else if(error){
		    	this.setState({modalBody: "Sorry there was an error: " + error.message, modalHeader: "Error", loading: false, show: true})
		    }else{
		    	this.setState({modalBody: "Sorry there was an error: " + error, modalHeader: "Error", loading: false, show: true})
		    }
		});
  	}

  	changeRadio(e){
	  	if(this.state[e.target.name] == false){
	  		this.setState({[e.target.name]: true});
	  	}else{
	  		this.setState({[e.target.name]: false});
	  	}
  	}

  	handleChange(e){
  		this.setState({[e.target.name]:e.target.value});
  	}

  	updateSearch(e){
  		this.setState({searchString: e.target.value});
  		var searchString = e.target.value;
		if(searchString != ""){
		  	const searchResults = this.state.contacts.filter(contact => 
		  		contact.username.toLowerCase().includes(searchString.toLowerCase()) ||
		  		contact.email.toLowerCase().includes(searchString.toLowerCase()) ||
		  		contact.contactId.toLowerCase().includes(searchString.toLowerCase()) ||
		  		contact.phone.toLowerCase().includes(searchString.toLowerCase())
		  	);
		  	this.setState({searching: true, filteredContacts: searchResults});
		}else{
			this.setState({searching: false, filteredContacts: []});
		}
  	}

  	updateUserSucccess(){
  		let value = this.context;
	    axios.post('https://www.arremoteassistance.com/api/v1/contacts/read.php',{},{headers: {Authorization: 'Bearer ' + value.jwt}})
	      .then(res => {
			this.setState({loading: false, contacts: res.data.message});
	    }).catch(error => {
		    if(error.response){
		      	if(error.response.status == 401){
		        	this.setState({loading: false});
            		value.logout();
		      	}else{
		        	this.setState({modalBody: "Sorry, we were unable to retrieve contacts. Please try again.", modalHeader: "Error", show: true, loading: false});
		      	}
		    }else if(error){
		    	this.setState({modalBody: "Sorry there was an error: " + error.message, modalHeader: "Error", loading: false, show: true});
		    }else{
		    	this.setState({modalBody: "Sorry there was an error: " + error, modalHeader: "Error", loading: false, show: true});
		    }
		});
  		this.setState({modalBody: "Contact was successfully updated", modalHeader: "Success", loading: false, show: true});
  	}

  	updateUserError(){
  		this.setState({modalBody: "Unable to update contacts. Please verify no information was left blank", modalHeader: "Error", loading: false, show: true});
  	}

  	deleteUserSuccess(){
	  	let value = this.context;
	  	axios.post('https://www.arremoteassistance.com/api/v1/contacts/read.php',{},{headers: {Authorization: 'Bearer ' + value.jwt}})
	      .then(res => {
			this.setState({loading: false, contacts: res.data.message});
	    }).catch(error => {
		    if(error.response){
		      	if(error.response.status == 401){
		        	this.setState({loading: false});
            		value.logout();
		      	}else{
		        	this.setState({modalBody: "Sorry, we were unable to retrieve contacts. Please try again.", modalHeader: "Error", show: true, loading: false});
		      	}
		    }else if(error){
		    	this.setState({modalBody: "Sorry there was an error: " + error.message, modalHeader: "Error", loading: false, show: true});
		    }else{
		    	this.setState({modalBody: "Sorry there was an error: " + error, modalHeader: "Error", loading: false, show: true});
		    }
		});
		this.setState({modalBody: "Contact was successfully deleted", modalHeader: "Success", loading: false, show: true});
  	}

  	deleteUserError(errorText){
  		this.setState({modalBody: errorText, modalHeader: "Error", loading: false, show: true})
  	}

  	showModal(){
    	this.setState({show: true});
  	}

  	closeModal(){
    	this.setState({show: false});
  	}

  	render(){
	  	let value = this.context;
	  	const {loading, users, authorized, show} = this.state;
	  	if(value.jwt == ""){
	      return(
	        <Route>
	          <Redirect to="/login" />
	        </Route>
	      );
	    }else{
		    return (
		   		<div className="mainWrapper">
		    		<div className="mainWindow">
			   			{this.state.loading &&
			   				<LoadingSpinner />
			   			}
			   			<Modal show={show} onHide={this.closeModal}>
				            <Modal.Header closeButton>
				              <Modal.Title>{this.state.modalHeader}</Modal.Title>
				            </Modal.Header>
				            <Modal.Body>{this.state.modalBody}</Modal.Body>
				            <Modal.Footer>
				              <Button variant="secondary" onClick={this.closeModal}>
				                Close
				              </Button>
				            </Modal.Footer>
				        </Modal>
				      	<div className="pageHeader">
					      	CONTACTS 
			      			<NavLink to="/contact/create" >
			      				<Button variant="primary" className="addUserBtn">+ Add Contact</Button>
			      			</NavLink>
			      			<div className="projectsHeaderSearch">
					            <input type="text" className="projectsHeaderSearch" id="projectsHeaderSearch" placeholder="Search..." value={this.state.searchString} onChange={this.updateSearch} />
					        </div>
				      	</div>
				      	<div className="plainBody">
				      		<div className="userFilter">
				      			<div className="userFilterUsername">
				      			Full Name <img src='/images/sorticon.png' alt="userFilter"/>
				      			</div>
				      			<div className="userFilterContactId">
				      			Contact ID <img src='/images/sorticon.png' alt="userFilter"/>
				      			</div>
				      			<div className="userFilterLicense">
				      			Phone # <img src='/images/sorticon.png' alt="userFilter"/>
				      			</div>
				      			<div className="userFilterRole">
				      			Email
				      			</div>
				      			<div className="userFilterUpdate">
				      			</div>
				      		</div>
				      		<div className="userViewWrapper">
					      		{(this.state.searching == false) ? (
						      		this.state.contacts.map(contact => {
						      			return(
											<UserContact key={contact.id} userName={contact.username} userId={contact.id} userPhone={contact.phone} userEmail={contact.email} updateSuccess={this.updateUserSucccess} deleteSuccess={this.deleteUserSuccess} updateError={this.updateUserError} deleteError={this.deleteUserError} contactId={contact.contactId} />							        
										);
						       		})
						       	)
						       	:
						       	(this.state.filteredContacts.map(contact => {
						       		return(
								        <UserContact key={contact.id} userName={contact.username} userId={contact.id} userPhone={contact.phone} userEmail={contact.email} updateSuccess={this.updateUserSucccess} deleteSuccess={this.deleteUserSuccess} updateError={this.updateUserError} deleteError={this.deleteUserError} contactId={contact.contactId} />
								    );
						       	})
						       	)
						      	}
				      		</div>
				      	</div>
				    </div>
		      	</div>
		    );
		}
  	}
}

export default ManageContacts;