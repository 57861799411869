import React from 'react';
import axios from 'axios';
import MainHeader from '../mainheader/MainHeader';
import MainFooter from '../mainfooter/MainFooter';
import {NavLink, Route} from 'react-router-dom';
import {Redirect} from 'react-router';
import { Modal, Button } from 'react-bootstrap';
import {Context} from "../configcontext/ConfigContext";
import {Elements, CardElement} from "@stripe/react-stripe-js";
import {loadStripe} from "@stripe/stripe-js";
import CheckoutForm from "../checkoutform/CheckoutForm.js";
import LoadingSpinner from '../loadingspinner/LoadingSpinner';
//import { PayPalButton } from "react-paypal-button-v2";
import './Pricing.css';

//USE THIS FOR LIVE MODE
const stripePromise = loadStripe("pk_live_51I3jzHAffrqkaVtAl3dY1dNQsIxr75bFWA1SlJaM4GFO0HHqOR6ov1w3Ts9AQQoJXMsjyNO8hVJk6MDhjD1cNd0s009CZGvMkL");

//USE THIS FOR TEST MODE
//const stripePromise = loadStripe("pk_test_51I3jzHAffrqkaVtA369wTnrrGSFZxJ0WvWDK1JOwkOlttJiJ73bU1HY4g1zdCcsnyLre8qPKdx3atEakObOVmkhV006XQUPmDF");

class Pricing extends React.Component{

  static contextType = Context;

  constructor(props) {
    super(props);
      this.state = {
        loading: false,
        modalHeader: "",
        modalBody: "",
        show: false
    }
    this.closeModal = this.closeModal.bind(this);
    this.showModal = this.showModal.bind(this);
  }

  showModal(){
    this.setState({show: true});
  }

  closeModal(){
    this.setState({show: false});
  }

  render(){
    let value = this.context;
    return (
      <div>
        {this.state.loading &&
            <LoadingSpinner />
        }
        <MainHeader />
        <Modal show={this.state.show} onHide={this.closeModal}>
            <Modal.Header closeButton>
                <Modal.Title>{this.state.modalHeader}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{this.state.modalBody}</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={this.closeModal}>
                  Close
                </Button>
            </Modal.Footer>
        </Modal>
        <div className="pageTitle">
          PRICING
        </div>
        <div className="pageDescription">
          <div className="plainBody">
            <div className="subscriptionWrapper">
              <Elements stripe={stripePromise}>
                <CheckoutForm productTitle="YEARLY SUBSCRIPTION" productPrice="10" productId="prod_If4K4DkPVUpO2u" priceId="price_1I3kBVAffrqkaVtADZxqCILY" productDescription="<ul><li>Billed Yearly</li><li>$120/year</li><li>Unlimited Voice/Video Chat Minutes</li><li>Over a 30% discount</li></ul>" createSubscription={this.createSubscription}/>
              </Elements>
            </div>
            <div className="subscriptionWrapper">
              <Elements stripe={stripePromise}>
                <CheckoutForm productTitle="MONTHLY SUBSCRIPTION" productPrice="15" productId="prod_If4JGwpYhIsGDz" priceId="price_1I3kAdAffrqkaVtAljNmyF3T" productDescription="<ul><li>Billed Monthly</li><li>$15/month</li><li>Unlimited Voice/Video Chat Minutes</li></ul>" createSubscription={this.createSubscription}/>
              </Elements>
            </div>
          </div>
        </div>
        <div className="footerSpace">
        </div>
        <MainFooter />
      </div>
    );
  }
}

export default Pricing;