import React from 'react';
import {Link} from 'react-router-dom';
import UserMenu from '../usermenu/UserMenu';
import {Context} from "../configcontext/ConfigContext";
import './Header.css';

class Header extends React.Component{

  static contextType = Context;
  
  constructor(props) {
    super(props);
    this.state = {
      showMenu: false
    }
    this.showMenu = this.showMenu.bind(this);
    this.handleMenu = this.handleMenu.bind(this);
  }

  showMenu(){
    if(this.state.showMenu == false){
      this.setState({showMenu: true});
    }else{
      this.setState({showMenu: false});
    }
  }

  handleMenu(value){
    this.setState({showMenu:value});
  }

  render(){
    let value = this.context;
    if (value.jwt == "") {
      return null;
    }else{
      return(
        <Context.Consumer>
          {data=>{
            return(
              <div className="topRail">
                <div className="logo">
                  <img src={'/images/arutility_icon.png'} alt="ARUtilityLogo"/>
                </div>
                <div className="companyName">
                  {data.companyName}
                </div>
                <div className="accountInfo">
                  <img src={'/images/profile.png'} alt="Profile Icon" />
                  <div className="usernameHeader">
                    {data.username}
                  </div>
                  <div className="accountInfoCarrot" onClick={this.showMenu}>
                  {this.state.showMenu ? 
                    <img src={'/images/upcarrot.png'} alt="Down Carrot" />
                    :
                    <img src={'/images/downcarrot.png'} alt="Up Carrot" />
                  }
                  </div>
                  { this.state.showMenu ? <UserMenu showMenu={this.state.showMenu} showMenuFunction={this.handleMenu}/> : null }
                </div>
              </div>
            );
          }}
        </Context.Consumer>
      );
    }
  }
}

export default Header;