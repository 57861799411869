import React from 'react';
import axios from 'axios';
import {Link} from 'react-router-dom';
import MainHeader from '../mainheader/MainHeader';
import MainFooter from '../mainfooter/MainFooter';
import {Context} from "../configcontext/ConfigContext";
import './Home.css';

class Home extends React.Component{

  static contextType = Context;

  constructor(props) {
    super(props);
      this.state = {
        newsArticles: [],
        newsImage: "",
        newsTitle: "",
        newsBody: "",
        newsDate: ""
    }
  }
  
  componentDidMount(){
    this.setState({loading: true});
    axios.get('https://www.arutility.com/api/v3/news/read.php')
    .then(res => {
      this.setState({newsArticles: res.data, loading: false});
      console.log(res.data);
    }).catch(error => {
        if (error.response) {
          this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.response.data, modalHeader: "Error", show: true});
      } else if (error.request) {
        // The request was made but no response was received
          this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.request, modalHeader: "Error", show: true});
      } else {
        // Something happened in setting up the request that triggered an Error
          this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.message, modalHeader: "Error", show: true});
      }
    });
  }

  render(){
    let value = this.context;
    return (
      <div>
        <MainHeader />
        <div className="mainVideoWrapper">
          <div className="heroContentAbout">
            <div className="heroContentHeader">
              <b>AUGMENTED REALITY<br/>REMOTE ASSISTANCE</b>
            </div>
            <br/>
            <div className="heroContentBody">
            Get help when you need it, where you need it<br/>
            from anywhere in the world
            </div>
            <Link to="/account/create">
              <button className="trialButton" name="freeTrial">Start Your Free Trial</button>
            </Link>
          </div>
          <div className="videoOverlay">
          </div>
          <video preload="auto" id="video" autoPlay={true} muted={true} loop={true}>
            <source src="/images/homepreview.mp4" type="video/mp4" />
          </video>
        </div>
        <div className="homeBody">
          <div className="homeBodyHeading">
            Empower your teams to 
            <br/>succeed in today’s 
            <br/>new normal
          </div>
          <div className="homeBodyMain">
            <div className="homeBodyMainLeft">
              <div className="imageOverlay"></div>
              <img src="/images/locatingscreenshot.png" />
            </div>
            <div className="homeBodyMainRight">
              The global pandemic has changed the way we work – and for many manufacturers, the change will be permanent.
              <br/>
              <br/>
              Despite the shift to remote work, there are still nearly 2 billion frontline or field workers in the global workforce. Most of these frontline jobs cannot be done remotely, yet these workers still need access to digital information, guidance, training, and support, which was previously given in person.
              <br/>
              <br/>
              Unfortunately, typical videoconferencing and virtual collaboration tools (e.g Zoom, Microsoft Teams), which are designed for desktop-based knowledge workers, are not always appropriate for the workplace of the frontline – workers on factory floors or healthcare facilities, engineers on oil rigs, or field service technicians out in the field.
            </div>
          </div>
        </div>
        <div className="mainPageExplanation">
          <Link to="/about">
            <button className="learnMoreBtn">LEARN MORE</button>
          </Link>
        </div>
        <div className="footerSpace">
        </div>
        <MainFooter />
      </div>
    );
  }
}

export default Home;