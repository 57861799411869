import React from 'react';
import axios from 'axios';
import {Link} from 'react-router-dom';
import MainHeader from '../mainheader/MainHeader';
import MainFooter from '../mainfooter/MainFooter';
import { Modal, Button } from 'react-bootstrap';
import './Login.css';

class Login extends React.Component{

  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      subdomain: "",
      show: false,
      modalBody: "",
      modalHeader: "",
      loading: true,
      utilityAccount: false
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleAuth0Submit = this.handleAuth0Submit.bind(this);
    this.showModal = this.showModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  componentDidMount() {
    let host = window.location.host;
    //let parts = host.split(".");
    //var subDomain = parts[0];
    var subDomain = this.props.match.params.accountName;
    //if(subDomain == "" || subDomain == "www" || subDomain == "localhost:3000"){
    if(subDomain == "" || subDomain == null){
      this.setState({loading: false});
    }else{
      this.setState({subdomain: subDomain, loading: false, utilityAccount: true});
    }
  }

  handleAuth0Submit(){
    this.setState({loading: true});
    if(this.state.username != "" && this.state.password != ""){
      axios.post('https://www.arremoteassistance.com/api/v1/users/login.php',{username: this.state.username,password:this.state.password})
      //axios.post('https://www.arremoteassistance.com/api/v1/users/loginDev.php',{username: this.state.username,password:this.state.password})       
        .then(res => {
            this.setState({loading: false});
            window.location.href=res.data.url;
        }).catch(error => {
          if (error.response) {
              this.setState({loading: false, modalBody: error.response.data.error, modalHeader: "Error", show: true});
          } else if (error.request) {
            // The request was made but no response was received
              this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.request, modalHeader: "Error", show: true});
          } else {
            // Something happened in setting up the request that triggered an Error
              this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.message, modalHeader: "Error", show: true});
          }
        });
    }else{
      this.setState({loading: false, modalBody: "Sorry, it appears you are missing required information", modalHeader: "Error", show: true});
    }
  }

  handleChange(e) {
    this.setState({[e.target.id]: e.target.value});
  }

  showModal(){
    this.setState({show: true});
  }

  closeModal(){
    this.setState({show: false});
  }

  render(){
    const{show} = this.state;
    return (
      <div className="mainWrapper">
        <Modal show={show} onHide={this.closeModal}>
          <Modal.Header closeButton>
            <Modal.Title>{this.state.modalHeader}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{this.state.modalBody}</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.closeModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        <MainHeader />
      	<div className="pageTitle">
        USER LOGIN
        </div>
        <div className="pageDescription">
          <div className="createAccount">
            <div className="loginFormWrapper">
                <div className="loginAuth0">
                  <input type="text" id="username" name="username" placeholder="Email..." required="" value={this.state.username} onChange={this.handleChange}/>
                  <br/>
                  <input type="password" id="password" name="password" placeholder="Password..." required="" value={this.state.password} onChange={this.handleChange}/>
                  <br/>
                  <button type="submit" value="Submit" onClick={this.handleAuth0Submit}>LOGIN</button>
                  <br/>
                  <Link to="/account/create">
                    Register New Account
                  </Link>
                  &nbsp;&nbsp;|&nbsp;&nbsp;
                  <Link to="/forgotpassword">
                    Forgot Password
                  </Link>
                </div>
            </div>
          </div>
        </div>
        <div className="footerSpace">
        </div>
        <MainFooter />
      </div>
    );
  }
}

export default Login;