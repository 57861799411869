import React from 'react';
import axios from 'axios';
import MainHeader from '../mainheader/MainHeader';
import MainFooter from '../mainfooter/MainFooter';
import {Context} from "../configcontext/ConfigContext";
import { Modal, Button } from 'react-bootstrap';
import LoadingSpinner from '../loadingspinner/LoadingSpinner';
import 'bootstrap/dist/css/bootstrap.min.css';
import './CreateAccount.css';
import ReCAPTCHA from "react-google-recaptcha";

class CreateAccount extends React.Component{

  static contextType = Context;

  constructor(props) {
    super(props);
      this.state = {
        accountType: "individual",
        alias: "",
        firstName: "",
        lastName: "",
        companyName: "",
        companyAddr1: "",
        companyAddr2: "",
        companyCity: "",
        companyState: "",
        companyCountry: "",
        companyPhone: "",
        adminEmail: "",
        password1: "",
        password2: "",
        verified: false,
        loading: false,
        modalBody: "",
        modalHeader: "",
        noAlias: true,
        aliasAvailable: false,
        aliasUnavailable: false,
        companyNameNull: false,
        companyPhoneNull: false,
        companyAliasNull: false,
        firstNameNull: false,
        lastNameNull: false,
        companyCountryNull: false,
        companyStateNull: false,
        companyCityNull: false,
        companyAddr1Null: false,
        adminEmailNull: false,
        password1Null: false,
        password2Null: false,
        show: false
    }
    this.updateForm = this.updateForm.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.sendForm = this.sendForm.bind(this);
    this.recaptchaVerified = this.recaptchaVerified.bind(this);
    this.recaptchaExpired = this.recaptchaExpired.bind(this);
    this.showModal = this.showModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  recaptchaExpired(){
    this.setState({verified: false});
  }

  recaptchaVerified(){
    this.setState({verified: true});
  }

  updateForm(e){
    if(e.target.id == "alias"){
        this.setState({[e.target.id]:e.target.value});
        if(e.target.value != "" && e.target.value != " "){
            axios.post('http://www.arremoteassistance.com/api/v1/accounts/checkExists.php',{alias: e.target.value})
            .then(res => {
                if(res.data.message == "Alias exists"){
                    this.setState({aliasAvailable: false, aliasUnavailable: true, noAlias: false});
                }else if(res.data.message == "Alias does not exist"){
                    this.setState({aliasAvailable: true, aliasUnavailable: false, noAlias: false});
                }
                //this.setState({loading: false, modalHeader: "Success", modalBody: res.data.message, show: true});
            }).catch(error => {
                if (error.response) {
                    this.setState({loading: false, modalBody: error.response.data.message, modalHeader: "Error", show: true});
                } else if (error.request) {
                    // The request was made but no response was received
                    this.setState({loading: false, modalBody: "Sorry, there was an error. " + error, modalHeader: "Error", show: true});
                } else {
                    console.log(error);
                    // Something happened in setting up the request that triggered an Error
                    this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.message, modalHeader: "Error", show: true});
                }
            });
        }else{
            this.setState({noAlias: true, aliasAvailable: false, aliasUnavailable: false});
        }
    }else{
        this.setState({[e.target.id]:e.target.value});
    }
  }

  showModal(){
    this.setState({show: true});
  }

  closeModal(){
    this.setState({show: false});
  }

  sendForm(){
  	axios.post('https://www.arremoteassistance.com/api/v1/accounts/create.php',{accountType: this.state.accountType, firstName: this.state.firstName, lastName: this.state.lastName, companyName: this.state.companyName, companyAddr1: this.state.companyAddr1, companyAddr2: this.state.companyAddr2, companyCity: this.state.companyCity, companyState: this.state.companyState, companyCountry: this.state.companyCountry, adminEmail: this.state.adminEmail, companyPhone: this.state.companyPhone, password: this.state.password1, alias: this.state.alias})
	.then(res => {
	    this.setState({loading: false, modalHeader: "Success", modalBody: res.data.message, show: true});
	}).catch(error => {
	    if (error.response) {
	        this.setState({loading: false, modalBody: error.response.data.error, modalHeader: "Error", show: true});
	    } else if (error.request) {
	      // The request was made but no response was received
	        this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.request, modalHeader: "Error", show: true});
	    } else {
	      // Something happened in setting up the request that triggered an Error
	        this.setState({loading: false, modalBody: "Sorry, there was an error. " + error.message, modalHeader: "Error", show: true});
	    }
	});
  }

  submitForm(){
    if(this.state.verified){
      	if(this.state.accountType != "" && this.state.accountType == "enterprise"){
    	    if(this.state.companyName != "" && this.state.companyPhone != "" && this.state.adminEmail != "" && this.state.firstName != "" && this.state.lastName != "" && this.state.password1 != "" && this.state.password2 != "" && this.state.companyCountry != "" && this.state.companyState != "" && this.state.companyCity != "" && this.state.companyAddr1 != ""){
    	        this.setState({loading: true});
    	        this.sendForm();	      
    	    }else{
                if(this.state.companyName == ""){
                    this.setState({companyNameNull: true});
                }else{
                    this.setState({companyNameNull: false});
                }
                if(this.state.companyPhone == ""){
                    this.setState({companyPhoneNull: true});
                }else{
                    this.setState({companyPhoneNull: false});
                }
                if(this.state.adminEmail == ""){
                    this.setState({adminEmailNull: true});
                }else{
                    this.setState({adminEmailNull: false});
                }
                if(this.state.firstName == ""){
                    this.setState({firstNameNull: true});
                }else{
                    this.setState({firstNameNull: false});
                }
                if(this.state.lastName == ""){
                    this.setState({lastNameNull: true});
                }else{
                    this.setState({lastNameNull: false});
                }
                {/*if(this.state.alias == ""){
                    this.setState({companyAliasNull: true});
                }else{
                    this.setState({companyAliasNull: false});
                }*/}
                if(this.state.password1 == ""){
                    this.setState({password1Null: true});
                }else{
                    this.setState({password1Null: false});
                }
                if(this.state.password2 == ""){
                    this.setState({password2Null: true});
                }else{
                    this.setState({password2Null: false});
                }
                if(this.state.companyCountry == ""){
                    this.setState({companyCountryNull: true});
                }else{
                    this.setState({companyCountryNull: false});
                }
                if(this.state.companyCity == ""){
                    this.setState({companyCityNull: true});
                }else{
                    this.setState({companyCityNull: false});
                }
                if(this.state.companyState == ""){
                    this.setState({companyStateNull: true});
                }else{
                    this.setState({companyStateNull: false});
                }
                if(this.state.companyAddr1 == ""){
                    this.setState({companyAddr1Null: true});
                }else{
                    this.setState({companyAddr1Null: false});
                }
    	        this.setState({loading: false, modalBody: "Sorry, it looks like some information is missing", modalHeader: "Error", show: true});
    	    }
    	}else if(this.state.accountType != "" && (this.state.accountType == "individual" || this.state.accountType == "free")){
    		if(this.state.companyPhone != "" && this.state.adminEmail != "" && this.state.firstName != "" && this.state.lastName != "" && this.state.password1 != "" && this.state.password2 != "" && this.state.companyCountry != "" && this.state.companyState != "" && this.state.companyCity != ""){
    			if(this.state.password1 == this.state.password2){
    		      this.setState({loading: true, alias: ""});
    		      this.sendForm();
    		    }else{
    		    	this.setState({loading: false, modalBody: "Sorry, passwords must match. Verify passwords and try again.", modalHeader: "Error", show: true});
    		    }
    		}else{
                if(this.state.companyName == ""){
                    this.setState({companyNameNull: true});
                }else{
                    this.setState({companyNameNull: false});
                }
                if(this.state.companyPhone == ""){
                    this.setState({companyPhoneNull: true});
                }else{
                    this.setState({companyPhoneNull: false});
                }
                if(this.state.adminEmail == ""){
                    this.setState({adminEmailNull: true});
                }else{
                    this.setState({adminEmailNull: false});
                }
                if(this.state.firstName == ""){
                    this.setState({firstNameNull: true});
                }else{
                    this.setState({firstNameNull: false});
                }
                if(this.state.lastName == ""){
                    this.setState({lastNameNull: true});
                }else{
                    this.setState({lastNameNull: false});
                }
                {/*if(this.state.alias == ""){
                    this.setState({companyAliasNull: true});
                }else{
                    this.setState({companyAliasNull: false});
                }*/}
                if(this.state.password1 == ""){
                    this.setState({password1Null: true});
                }else{
                    this.setState({password1Null: false});
                }
                if(this.state.password2 == ""){
                    this.setState({password2Null: true});
                }else{
                    this.setState({password2Null: false});
                }
                if(this.state.companyCountry == ""){
                    this.setState({companyCountryNull: true});
                }else{
                    this.setState({companyCountryNull: false});
                }
                if(this.state.companyCity == ""){
                    this.setState({companyCityNull: true});
                }else{
                    this.setState({companyCityNull: false});
                }
                if(this.state.companyState == ""){
                    this.setState({companyStateNull: true});
                }else{
                    this.setState({companyStateNull: false});
                }
                if(this.state.companyAddr1 == ""){
                    this.setState({companyAddr1Null: true});
                }else{
                    this.setState({companyAddr1Null: false});
                }
    	      this.setState({loading: false, modalBody: "Sorry, it looks like some information is missing", modalHeader: "Error", show: true});
    	    }
    	}
    }else{
        this.setState({loading: false, modalBody: "Sorry, you did not complete the captcha", modalHeader: "Error", show: true});
    }
  }

  render(){
    return (
      <div>
      	{this.state.loading &&
            <LoadingSpinner />
        }
        <MainHeader />
        <Modal show={this.state.show} onHide={this.closeModal}>
            <Modal.Header closeButton>
                <Modal.Title>{this.state.modalHeader}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{this.state.modalBody}</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={this.closeModal}>
                  Close
                </Button>
            </Modal.Footer>
        </Modal>
        <div className="pageTitle">
          CREATE ACCOUNT
        </div>
        <div className="pageDescription">
            <div className="createAccount">
                Create an account for FREE to make and receive calls up to 2 minutes long at no charge. When you're ready to upgrade, add a license for unlimited voice and video chats.
                <br/>
                <br/>
      	        <font color="#c62828">Account Type:*</font>
                <br/>
                <select className="projectSelect" id="accountType" value={this.state.accountType} onChange={this.updateForm}>
                    <option value="individual">Individual</option>
                	<option value="enterprise">Enterprise</option>
                </select> 
                <div className="accountTypeInfo">
                	<img src="/images/info.png" alt="More Info"/>
                	<div className="infoPopup">
                    {this.state.accountType == "free" &&
                        <div>
                            Free accounts are for single users. You will not be able to add additional users to your account. Calls are limited to 2 minutes at a time and you will not be able to record your sessions.
                        </div>
                    }
                	{this.state.accountType == "individual" &&
                		<div>
                			Individual accounts are for single users. You will not be able to add additional users to your account. This account type is not intended for commercial or enterprise businesses.
                		</div>
                	}
                	{this.state.accountType == "enterprise" &&
                		<div>
                			Enterprise accounts are intended for businesses that will need multiple users on one account. This is not intended for individuals.
                		</div>
                	}
                	</div>
                </div>
                <br/>
                <br/>
                {/*{this.state.accountType == "enterprise" &&
                    }<div>
                        <font color="#c62828">Company Alias:*</font>  
                        <br/>
                        <input type="text" maxLength="13" value={this.state.alias} id="alias" className={this.state.companyAliasNull ? 'companyInputNull': 'companyInput'} onChange={this.updateForm} />
                        <div className="aliasVerification">
                            {this.state.aliasAvailable == true &&
                                <div>
                                    <img src="/images/aliasAvailable.png" alt="Alias Available" /><font color="#48bc4a">&nbsp;Alias Available!</font>
                                </div>
                            }
                            {this.state.aliasUnavailable == true &&
                                <div>
                                    <img src="/images/aliasUnavailable.png" alt="Alias Unavailable" /><font color="#bf4d4c">&nbsp;Alias Unavailable!</font>
                                </div>
                            }
                        </div>
                        <br/>
                        <b>Your login URL will be: </b><font color="blue">www.arremoteassistance.com/login/{this.state.alias}</font>
                        <br/>
                        Please save this URL to sign in upon successful account creation.
                        <br/> 
                        <br/>
                    </div>
                }*/}
                <font color="#c62828">First Name:*</font>
                <br/>
                <input type="text" value={this.state.firstName} id="firstName" className={this.state.firstNameNull ? 'companyInputNull': 'companyInput'} onChange={this.updateForm} />
                <br/>
                <br/>
                <font color="#c62828">Last Name:*</font>
                <br/>
                <input type="text" value={this.state.lastName} id="lastName" className={this.state.lastNameNull ? 'companyInputNull': 'companyInput'} onChange={this.updateForm} />
                <br/>
                <br/>
                <font color="#c62828">Contact Number:*</font>
                <br/>
                <input type="tel" value={this.state.companyPhone} id="companyPhone" className={this.state.companyPhoneNull ? 'companyInputNull': 'companyInput'} onChange={this.updateForm} />
                <br/>
                <br/>
                {(this.state.accountType != "individual" && this.state.accountType != "free") &&
                	<div>
    	            	<font color="#c62828">Company Name:*</font>
    		            <br/>
    		            <input type="text" value={this.state.companyName} id="companyName" className={this.state.companyNameNull ? 'companyInputNull': 'companyInput'}  onChange={this.updateForm} />
    		            <br/>
    		            <br/>
    		        </div>
            	}
                <font color="#c62828">Country:*</font>
                <br/>
                <select className="projectSelect" id="companyCountry" value={this.state.companyCountry} onChange={this.updateForm}>
                    <option value=""></option>
                    <option value="Afganistan">Afghanistan</option>
                    <option value="Albania">Albania</option>
                    <option value="Algeria">Algeria</option>
                    <option value="American Samoa">American Samoa</option>
                    <option value="Andorra">Andorra</option>
                    <option value="Angola">Angola</option>
                    <option value="Anguilla">Anguilla</option>
                    <option value="Antigua & Barbuda">Antigua & Barbuda</option>
                    <option value="Argentina">Argentina</option>
                    <option value="Armenia">Armenia</option>
                    <option value="Aruba">Aruba</option>
                    <option value="Australia">Australia</option>
                    <option value="Austria">Austria</option>
                    <option value="Azerbaijan">Azerbaijan</option>
                    <option value="Bahamas">Bahamas</option>
                    <option value="Bahrain">Bahrain</option>
                    <option value="Bangladesh">Bangladesh</option>
                    <option value="Barbados">Barbados</option>
                    <option value="Belarus">Belarus</option>
                    <option value="Belgium">Belgium</option>
                    <option value="Belize">Belize</option>
                    <option value="Benin">Benin</option>
                    <option value="Bermuda">Bermuda</option>
                    <option value="Bhutan">Bhutan</option>
                    <option value="Bolivia">Bolivia</option>
                    <option value="Bonaire">Bonaire</option>
                    <option value="Bosnia & Herzegovina">Bosnia & Herzegovina</option>
                    <option value="Botswana">Botswana</option>
                    <option value="Brazil">Brazil</option>
                    <option value="British Indian Ocean Ter">British Indian Ocean Ter</option>
                    <option value="Brunei">Brunei</option>
                    <option value="Bulgaria">Bulgaria</option>
                    <option value="Burkina Faso">Burkina Faso</option>
                    <option value="Burundi">Burundi</option>
                    <option value="Cambodia">Cambodia</option>
                    <option value="Cameroon">Cameroon</option>
                    <option value="Canada">Canada</option>
                    <option value="Canary Islands">Canary Islands</option>
                    <option value="Cape Verde">Cape Verde</option>
                    <option value="Cayman Islands">Cayman Islands</option>
                    <option value="Central African Republic">Central African Republic</option>
                    <option value="Chad">Chad</option>
                    <option value="Channel Islands">Channel Islands</option>
                    <option value="Chile">Chile</option>
                    <option value="China">China</option>
                    <option value="Christmas Island">Christmas Island</option>
                    <option value="Cocos Island">Cocos Island</option>
                    <option value="Colombia">Colombia</option>
                    <option value="Comoros">Comoros</option>
                    <option value="Congo">Congo</option>
                    <option value="Cook Islands">Cook Islands</option>
                    <option value="Costa Rica">Costa Rica</option>
                    <option value="Cote DIvoire">Cote DIvoire</option>
                    <option value="Croatia">Croatia</option>
                    <option value="Cuba">Cuba</option>
                    <option value="Curaco">Curacao</option>
                    <option value="Cyprus">Cyprus</option>
                    <option value="Czech Republic">Czech Republic</option>
                    <option value="Denmark">Denmark</option>
                    <option value="Djibouti">Djibouti</option>
                    <option value="Dominica">Dominica</option>
                    <option value="Dominican Republic">Dominican Republic</option>
                    <option value="East Timor">East Timor</option>
                    <option value="Ecuador">Ecuador</option>
                    <option value="Egypt">Egypt</option>
                    <option value="El Salvador">El Salvador</option>
                    <option value="Equatorial Guinea">Equatorial Guinea</option>
                    <option value="Eritrea">Eritrea</option>
                    <option value="Estonia">Estonia</option>
                    <option value="Ethiopia">Ethiopia</option>
                    <option value="Falkland Islands">Falkland Islands</option>
                    <option value="Faroe Islands">Faroe Islands</option>
                    <option value="Fiji">Fiji</option>
                    <option value="Finland">Finland</option>
                    <option value="France">France</option>
                    <option value="French Guiana">French Guiana</option>
                    <option value="French Polynesia">French Polynesia</option>
                    <option value="French Southern Ter">French Southern Ter</option>
                    <option value="Gabon">Gabon</option>
                    <option value="Gambia">Gambia</option>
                    <option value="Georgia">Georgia</option>
                    <option value="Germany">Germany</option>
                    <option value="Ghana">Ghana</option>
                    <option value="Gibraltar">Gibraltar</option>
                    <option value="Great Britain">Great Britain</option>
                    <option value="Greece">Greece</option>
                    <option value="Greenland">Greenland</option>
                    <option value="Grenada">Grenada</option>
                    <option value="Guadeloupe">Guadeloupe</option>
                    <option value="Guam">Guam</option>
                    <option value="Guatemala">Guatemala</option>
                    <option value="Guinea">Guinea</option>
                    <option value="Guyana">Guyana</option>
                    <option value="Haiti">Haiti</option>
                    <option value="Hawaii">Hawaii</option>
                    <option value="Honduras">Honduras</option>
                    <option value="Hong Kong">Hong Kong</option>
                    <option value="Hungary">Hungary</option>
                    <option value="Iceland">Iceland</option>
                    <option value="Indonesia">Indonesia</option>
                    <option value="India">India</option>
                    <option value="Iran">Iran</option>
                    <option value="Iraq">Iraq</option>
                    <option value="Ireland">Ireland</option>
                    <option value="Isle of Man">Isle of Man</option>
                    <option value="Israel">Israel</option>
                    <option value="Italy">Italy</option>
                    <option value="Jamaica">Jamaica</option>
                    <option value="Japan">Japan</option>
                    <option value="Jordan">Jordan</option>
                    <option value="Kazakhstan">Kazakhstan</option>
                    <option value="Kenya">Kenya</option>
                    <option value="Kiribati">Kiribati</option>
                    <option value="Korea North">Korea North</option>
                    <option value="Korea Sout">Korea South</option>
                    <option value="Kuwait">Kuwait</option>
                    <option value="Kyrgyzstan">Kyrgyzstan</option>
                    <option value="Laos">Laos</option>
                    <option value="Latvia">Latvia</option>
                    <option value="Lebanon">Lebanon</option>
                    <option value="Lesotho">Lesotho</option>
                    <option value="Liberia">Liberia</option>
                    <option value="Libya">Libya</option>
                    <option value="Liechtenstein">Liechtenstein</option>
                    <option value="Lithuania">Lithuania</option>
                    <option value="Luxembourg">Luxembourg</option>
                    <option value="Macau">Macau</option>
                    <option value="Macedonia">Macedonia</option>
                    <option value="Madagascar">Madagascar</option>
                    <option value="Malaysia">Malaysia</option>
                    <option value="Malawi">Malawi</option>
                    <option value="Maldives">Maldives</option>
                    <option value="Mali">Mali</option>
                    <option value="Malta">Malta</option>
                    <option value="Marshall Islands">Marshall Islands</option>
                    <option value="Martinique">Martinique</option>
                    <option value="Mauritania">Mauritania</option>
                    <option value="Mauritius">Mauritius</option>
                    <option value="Mayotte">Mayotte</option>
                    <option value="Mexico">Mexico</option>
                    <option value="Midway Islands">Midway Islands</option>
                    <option value="Moldova">Moldova</option>
                    <option value="Monaco">Monaco</option>
                    <option value="Mongolia">Mongolia</option>
                    <option value="Montserrat">Montserrat</option>
                    <option value="Morocco">Morocco</option>
                    <option value="Mozambique">Mozambique</option>
                    <option value="Myanmar">Myanmar</option>
                    <option value="Nambia">Nambia</option>
                    <option value="Nauru">Nauru</option>
                    <option value="Nepal">Nepal</option>
                    <option value="Netherland Antilles">Netherland Antilles</option>
                    <option value="Netherlands">Netherlands (Holland, Europe)</option>
                    <option value="Nevis">Nevis</option>
                    <option value="New Caledonia">New Caledonia</option>
                    <option value="New Zealand">New Zealand</option>
                    <option value="Nicaragua">Nicaragua</option>
                    <option value="Niger">Niger</option>
                    <option value="Nigeria">Nigeria</option>
                    <option value="Niue">Niue</option>
                    <option value="Norfolk Island">Norfolk Island</option>
                    <option value="Norway">Norway</option>
                    <option value="Oman">Oman</option>
                    <option value="Pakistan">Pakistan</option>
                    <option value="Palau Island">Palau Island</option>
                    <option value="Palestine">Palestine</option>
                    <option value="Panama">Panama</option>
                    <option value="Papua New Guinea">Papua New Guinea</option>
                    <option value="Paraguay">Paraguay</option>
                    <option value="Peru">Peru</option>
                    <option value="Phillipines">Philippines</option>
                    <option value="Pitcairn Island">Pitcairn Island</option>
                    <option value="Poland">Poland</option>
                    <option value="Portugal">Portugal</option>
                    <option value="Puerto Rico">Puerto Rico</option>
                    <option value="Qatar">Qatar</option>
                    <option value="Republic of Montenegro">Republic of Montenegro</option>
                    <option value="Republic of Serbia">Republic of Serbia</option>
                    <option value="Reunion">Reunion</option>
                    <option value="Romania">Romania</option>
                    <option value="Russia">Russia</option>
                    <option value="Rwanda">Rwanda</option>
                    <option value="St Barthelemy">St Barthelemy</option>
                    <option value="St Eustatius">St Eustatius</option>
                    <option value="St Helena">St Helena</option>
                    <option value="St Kitts-Nevis">St Kitts-Nevis</option>
                    <option value="St Lucia">St Lucia</option>
                    <option value="St Maarten">St Maarten</option>
                    <option value="St Pierre & Miquelon">St Pierre & Miquelon</option>
                    <option value="St Vincent & Grenadines">St Vincent & Grenadines</option>
                    <option value="Saipan">Saipan</option>
                    <option value="Samoa">Samoa</option>
                    <option value="Samoa American">Samoa American</option>
                    <option value="San Marino">San Marino</option>
                    <option value="Sao Tome & Principe">Sao Tome & Principe</option>
                    <option value="Saudi Arabia">Saudi Arabia</option>
                    <option value="Senegal">Senegal</option>
                    <option value="Seychelles">Seychelles</option>
                    <option value="Sierra Leone">Sierra Leone</option>
                    <option value="Singapore">Singapore</option>
                    <option value="Slovakia">Slovakia</option>
                    <option value="Slovenia">Slovenia</option>
                    <option value="Solomon Islands">Solomon Islands</option>
                    <option value="Somalia">Somalia</option>
                    <option value="South Africa">South Africa</option>
                    <option value="Spain">Spain</option>
                    <option value="Sri Lanka">Sri Lanka</option>
                    <option value="Sudan">Sudan</option>
                    <option value="Suriname">Suriname</option>
                    <option value="Swaziland">Swaziland</option>
                    <option value="Sweden">Sweden</option>
                    <option value="Switzerland">Switzerland</option>
                    <option value="Syria">Syria</option>
                    <option value="Tahiti">Tahiti</option>
                    <option value="Taiwan">Taiwan</option>
                    <option value="Tajikistan">Tajikistan</option>
                    <option value="Tanzania">Tanzania</option>
                    <option value="Thailand">Thailand</option>
                    <option value="Togo">Togo</option>
                    <option value="Tokelau">Tokelau</option>
                    <option value="Tonga">Tonga</option>
                    <option value="Trinidad & Tobago">Trinidad & Tobago</option>
                    <option value="Tunisia">Tunisia</option>
                    <option value="Turkey">Turkey</option>
                    <option value="Turkmenistan">Turkmenistan</option>
                    <option value="Turks & Caicos Is">Turks & Caicos Is</option>
                    <option value="Tuvalu">Tuvalu</option>
                    <option value="Uganda">Uganda</option>
                    <option value="United Kingdom">United Kingdom</option>
                    <option value="Ukraine">Ukraine</option>
                    <option value="United Arab Erimates">United Arab Emirates</option>
                    <option value="United States of America">United States of America</option>
                    <option value="Uraguay">Uruguay</option>
                    <option value="Uzbekistan">Uzbekistan</option>
                    <option value="Vanuatu">Vanuatu</option>
                    <option value="Vatican City State">Vatican City State</option>
                    <option value="Venezuela">Venezuela</option>
                    <option value="Vietnam">Vietnam</option>
                    <option value="Virgin Islands (Brit)">Virgin Islands (Brit)</option>
                    <option value="Virgin Islands (USA)">Virgin Islands (USA)</option>
                    <option value="Wake Island">Wake Island</option>
                    <option value="Wallis & Futana Is">Wallis & Futana Is</option>
                    <option value="Yemen">Yemen</option>
                    <option value="Zaire">Zaire</option>
                    <option value="Zambia">Zambia</option>
                    <option value="Zimbabwe">Zimbabwe</option>
                </select>
                <br/>
                <br/>
                {this.state.companyCountry == "United States of America" ? 
                    <div>
                        <font color="#c62828">{(this.state.accountType != "individual" && this.state.accountType != "free") ? ("Company") : ("")} State:*</font>
                        <br/>
                        <select className="projectSelect" id="companyState" value={this.state.companyState} onChange={this.updateForm}>
                            <option value=""> </option>
                            <option value="AL">Alabama</option>
                            <option value="AK">Alaska</option>
                            <option value="AZ">Arizona</option>
                            <option value="AR">Arkansas</option>
                            <option value="CA">California</option>
                            <option value="CO">Colorado</option>
                            <option value="CT">Connecticut</option>
                            <option value="DE">Delaware</option>
                            <option value="DC">District Of Columbia</option>
                            <option value="FL">Florida</option>
                            <option value="GA">Georgia</option>
                            <option value="HI">Hawaii</option>
                            <option value="ID">Idaho</option>
                            <option value="IL">Illinois</option>
                            <option value="IN">Indiana</option>
                            <option value="IA">Iowa</option>
                            <option value="KS">Kansas</option>
                            <option value="KY">Kentucky</option>
                            <option value="LA">Louisiana</option>
                            <option value="ME">Maine</option>
                            <option value="MD">Maryland</option>
                            <option value="MA">Massachusetts</option>
                            <option value="MI">Michigan</option>
                            <option value="MN">Minnesota</option>
                            <option value="MS">Mississippi</option>
                            <option value="MO">Missouri</option>
                            <option value="MT">Montana</option>
                            <option value="NE">Nebraska</option>
                            <option value="NV">Nevada</option>
                            <option value="NH">New Hampshire</option>
                            <option value="NJ">New Jersey</option>
                            <option value="NM">New Mexico</option>
                            <option value="NY">New York</option>
                            <option value="NC">North Carolina</option>
                            <option value="ND">North Dakota</option>
                            <option value="OH">Ohio</option>
                            <option value="OK">Oklahoma</option>
                            <option value="OR">Oregon</option>
                            <option value="PA">Pennsylvania</option>
                            <option value="RI">Rhode Island</option>
                            <option value="SC">South Carolina</option>
                            <option value="SD">South Dakota</option>
                            <option value="TN">Tennessee</option>
                            <option value="TX">Texas</option>
                            <option value="UT">Utah</option>
                            <option value="VT">Vermont</option>
                            <option value="VA">Virginia</option>
                            <option value="WA">Washington</option>
                            <option value="WV">West Virginia</option>
                            <option value="WI">Wisconsin</option>
                            <option value="WY">Wyoming</option>
                        </select>
                        <br/>
                        <br/>
                    </div>
                    :
                    <div>
                        <font color="#c62828">{(this.state.accountType != "individual" && this.state.accountType != "free") ? ("Company") : ("")} State/Province/Region:*</font>
                        <br/>
                        <input type="text" value={this.state.companyState} id="companyState" className={this.state.companyStateNull ? 'companyInputNull': 'companyInput'} placeholder="State/Province/Region" onChange={this.updateForm}/>
                        <br/>
                        <br/>
                    </div>
                }
                <font color="#c62828">{(this.state.accountType != "individual" && this.state.accountType != "free") ? ("Company") : ("")} City/Town/Village:*</font>
                <br/>
                <input type="text" value={this.state.companyCity} id="companyCity" className={this.state.companyCityNull ? 'companyInputNull': 'companyInput'} placeholder="Lansing..." onChange={this.updateForm}/>
                <br/>
                <br/>
                <font color="#c62828">{(this.state.accountType != "individual" && this.state.accountType != "free") ? ("Company") : ("")} Address:*</font>
                <br/>
                <input type="text" value={this.state.companyAddr1} id="companyAddr1" className={this.state.companyAddr1Null ? 'companyInputNull': 'companyInput'} placeholder="123 Main St..." onChange={this.updateForm}/>
                <br/>
                <br/>
                <font color="#c62828">Address Cont:</font>
                <br/>
                <input type="text" value={this.state.companyAddr2} id="companyAddr2" className="companyInput" placeholder="Apt 1..." onChange={this.updateForm}/>
                <br/>
                <br/>
                <font color="#c62828">{(this.state.accountType != "individual" && this.state.accountType != "free") ? ("Admin") : ("")} {this.state.accountType == "utility" ? ("Esri Username:*") : ("Email:*")}</font>
                <br/>
                <input type="text" value={this.state.adminEmail} id="adminEmail" className={this.state.adminEmailNull ? 'companyInputNull': 'companyInput'} onChange={this.updateForm}/>
                <br/>
                <br/>
    			<div>
	        		<font color="#c62828">Password:*</font>
		            <br/>
		            <input type="password" value={this.state.password1} id="password1" className={this.state.password1Null ? 'companyInputNull': 'companyInput'} onChange={this.updateForm}/>
		            <br/>
		            <br/>
		            <font color="#c62828">Verify Password:*</font>
		            <br/>
		            <input type="password" value={this.state.password2} id="password2" className={this.state.password2Null ? 'companyInputNull': 'companyInput'} onChange={this.updateForm}/>
		            <br/>
		            <br/>
		        </div>
                    <div className="recaptchaContainer">
                        <ReCAPTCHA
                            sitekey="6LcxrbIZAAAAACJaryixzQfNikj2mTCLOeX77cnX"
                            size="normal"
                            onChange={this.recaptchaVerified}
                            onExpired={this.recaptchaExpired}
                        />
                    </div>
                    <br/>
                    <button value="Submit" id="createAccountSubmit" onClick={this.submitForm}>Create Account</button>
            </div>
        </div>
        <div className="footerSpace">
        </div>
        <MainFooter />
      </div>
    );
  }
}

export default CreateAccount;